import { Tabs } from 'antd';
import { translateWord } from 'utils/helpers';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import CrmCustomerTab from './customerTab/CrmCustomerTab';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useTab from 'components/jarvisly-components/hooks/useTabHook';
import entityService from 'services/entityService';
import CrmFollowupTab from './followupTab/CrmFollowupTab';
import { clone } from 'lodash';
import { useSelector } from 'react-redux';
import CrmParametersProvider from '../Parameters/ParametersProvider';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmForm = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, selectedModule, mode, setRequiredFields, requiredFields } =
    props;

  // local variables I ---------------------------------------------------------
  // const [searchParams] = useSearchParams();
  // const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'followup'];
  // const availableTabs = [initTab,  'requirement', 'proposal', 'followup'];
  const { parameters } = useSelector(s => s.moduleSlice);
  const moduleParameters = parameters?.crmParameters || {};

  const requirementTabRef = useRef(null);
  const proposalTabRef = useRef(null);

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTab(availableTabs, 'name');
  // const [formData, setFormData] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('company');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedTab: tab,
    // formData,
    initTab,

    selectedEntity,
    setSelectedEntity,
    entityType,
    setEntityType,
    tab,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const parameterRequiredFields = clone(
      moduleParameters?.entityRequiredFields || [],
    );

    if (Array.isArray(parameterRequiredFields)) {
      if (parameterRequiredFields.includes('cpfCnpj')) {
        parameterRequiredFields.push('cpf', 'cnpj');
      }
      if (parameterRequiredFields.includes('address')) {
        parameterRequiredFields.push('zip', 'number');
      }
    }

    setRequiredFields([
      ...new Set([...parameterRequiredFields, 'name', 'fullName']),
    ]);
  }, [moduleParameters?.entityRequiredFields]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    console.log('BODY =>', body);

    console.log('BODY =>', JSON.stringify(body));

    // create or update entity
    const entity = await entityService.persistyEntity(
      body,
      selectedEntity,
      entityType,
      'customer',
    );

    console.log('ENTITY ====>', entity);

    // post or put crm
    const dataSet = {
      ...body,
      entityId: entity?._id,
    };

    // ENTITY
    if (dataSet?.entity) delete dataSet.entity;

    // ATTACHMENTS
    // const requirementAttachs = requirementTabRef?.current?.attachments || [];
    // const proposalAttachs = proposalTabRef?.current?.attachments || [];
    //
    // const attachments = [...requirementAttachs, ...proposalAttachs];
    // dataSet.attachmentIds = appService.parseAttachmentsState(attachments);
    //
    // console.log('ATTACHMENTS ====>', attachments);

    console.log('PUT NO BACKEND =>', dataSet);

    return false;
    // return dataSet;
  };

  // UI COMPONENT --------------------------------------------------------------

  const mock = {
    __entity: {
      businessAreaId: '670148355c41ca38d14361e8',
      entityOrigin: 'new_entity',
      dataType: 'company',
      name: 'Empresa C',
      website: 'www.empresax.com.br',
      contactName: 'Fulano',
      phone: '(19) 9.1623-7612',
      email: 'fulano@teste.com.br',
    },
    opportunity: 'Falei com o Fulano sobre a parceria XYZ....',
    opportunityType: 'partner',
    originId: '67069397e5529ce9e0a0d17e',
    firstContactId: '667c8e062f82c9050e1f58de',
  };

  return (
    <CrmParametersProvider>
      {/* WRAPPER MODULE PARAMETERS MODAL */}
      {selectedModule?.components?.parameters
        ? selectedModule.components.parameters(childProps)
        : null}
      {/* WRAPPER MODULE PARAMETERS MODAL */}

      {/* WRAPPER BUSINESS AREA MODAL */}
      {/*<BusinessAreaModal {...childProps} />*/}
      {/* WRAPPER BUSINESS AREA MODAL */}

      <JarvislyFormProvider
        name="form-crm"
        document={mock}
        requiredFields={requiredFields}
        setTab={setTab}
        onSave={onSave}
        // onValuesChange={(v, allValues) => setFormData({ ...allValues })}
        showSubmitButton={tab !== 'followup'}
        // autoFocus={['__entity', 'selectedEntity']}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </CrmParametersProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItens() {
    return [
      {
        label: translateWord('details'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <CrmCustomerTab
              {...childProps}
              thisTab={initTab}
              context="entity"
            />
          </JarvislyTabProvider>
        ),
      },
      // {
      //   label: translateWord('requirement'),
      //   key: 'requirement',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: (
      //     <JarvislyTabProvider tab="requirement">
      //       <CrmRequirementTab
      //         {...childProps}
      //         activeTab="requirement"
      //         ref={requirementTabRef}
      //       />
      //     </JarvislyTabProvider>
      //   ),
      // },
      // {
      //   label: translateWord('proposal'),
      //   key: 'proposal',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: (
      //     <JarvislyTabProvider tab="proposal">
      //       <CrmProposalTab
      //         {...childProps}
      //         activeTab="proposal"
      //         ref={proposalTabRef}
      //       />
      //     </JarvislyTabProvider>
      //   ),
      // },
      {
        label: translateWord('followup'),
        key: 'followup',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="followup">
            <CrmFollowupTab {...childProps} thisTab="followup" />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default CrmForm;
