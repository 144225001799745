import { useState } from 'react';
import { translateX } from 'utils/helpers';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import LocationsTab from './locations/LocationsTab';
import LocationsModal from './locations/LocationsModal';
import SectorsTab from './sectors/SectorsTab';
import SectorsModal from './sectors/SectorsModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { showParametersModal, setShowParametersModal } = props;

  // local variables I ----------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states -----------------------------------------------------------
  const [tabKey, setTabKey] = useState('locations');
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showSectorsModal, setShowSectorsModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowParametersModal(false);
  };

  function onTabClick(key) {
    setTabKey(key);

    switch (key) {
      // case 'locations':
      //   configurationTabRef?.current?.getFirstFocus();
      //   break;

      // case 'requiredFields':
      //   requiredFieldsTabRef?.current?.getFirstFocus();
      //   break;

      default:
        break;
    }
  }
  // local variables III --------------------------------------------------------
  const childProps = {
    ...props,
    isDisabled: false,
    parameters,
    selectedRecord,
    setSelectedRecord,
  };

  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={translateX('parameters')}
        destroyOnClose={true}
        open={showParametersModal}
        // width={640}
        // okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        // onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        // okType={'primary'}
        // confirmLoading={isWorking}
        // afterClose={afterClose}
      >
        {/* WRAPPER LOCATIONS MODAL */}
        <LocationsModal
          {...childProps}
          showParametersModal={showLocationModal}
          setShowParametersModal={setShowLocationModal}
        />
        {/* WRAPPER LOCATIONS MODAL */}

        {/* WRAPPER SECTORS MODAL */}
        <SectorsModal
          {...childProps}
          showParametersModal={showSectorsModal}
          setShowParametersModal={setShowSectorsModal}
        />
        {/* WRAPPER SECTORS MODAL */}

        <Tabs onChange={onTabClick} items={tabItems} activeKey={tabKey} />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItems() {
    return [
      {
        label: translateX('locations'),
        key: 'locations',
        hide: false,
        children: (
          <LocationsTab
            {...childProps}
            showParametersModal={showLocationModal}
            setShowParametersModal={setShowLocationModal}
          />
        ),
      },
      {
        label: translateX('sectors'),
        key: 'sectors',
        hide: false,
        children: (
          <SectorsTab
            {...childProps}
            showParametersModal={showSectorsModal}
            setShowParametersModal={setShowSectorsModal}
          />
        ),
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const IotDevicesParametersModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return <ModalComponent {...props} />;
};

export default IotDevicesParametersModal;
