import { Card, Col, Row } from 'antd';
import { translateX } from 'utils/helpers';
import {
  JarvislyInput,
  JarvislySelect,
  JarvislySwitch
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appsindicoService from 'services/appsindicoService';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const VehiclesRecordTab = props => {
  // context deconstruction ----------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const carBrandOptions = appsindicoService.buildCarBrandOptions(parameters);
  const motorcycleBrandOptions =
    appsindicoService.buildMotorcycleBrandOptions(parameters);
  const typeVehiclesOptions =
    appsindicoService.buildTypeVehiclesOptions(parameters);
  const colorOptions = appsindicoService.buildColorOptions(parameters);
  // const mainDriverOptions = appsindicoService.buildMainDriverOptions(parameters);

  // component states ----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const [brandOptions, setBrandOptions] = useState([]);
  const [, setType] = useState('');

  const handleTypeChange = value => {
    setType(value);
    if (value === 'car') {
      setBrandOptions(carBrandOptions);
    } else if (value === 'motorcycle') {
      setBrandOptions(motorcycleBrandOptions);
    } else {
      setBrandOptions([]);
    }
  };

  // const onCustomerSelect = async value => {
  //   const doc = await appsindicoService.getPersonById(value.key);

  //   // setSelectedCustomer(doc || null);
  //   // setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

  //   // const body = entityService.decorateEntity(doc, 1, context);

  //   // form.setFieldsValue(body);
  //   // form.validateFields([[context, 'mobile']]);
  // };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('vehicle_data')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={16}>
            <JarvislySelect
              label="main_driver"
              name="mainDriver"
                // options={mainDriverOptions}
              required
            />
          </Col>
          {/* <Col xs={24} sm={8} lg={16}>
            <JarvislyAsyncAutoComplete
              name="selectedEntity"
              label="drawee"
              disabled={
                isDisabled || (document?.status && document.status !== 'new')
              }
              x-on-select={onCustomerSelect}
            />
          </Col> */}
          <Col xs={24} sm={16} lg={8}>
            <JarvislySelect
              label="vehicle_type"
              name="type"
              onChange={handleTypeChange}
              options={typeVehiclesOptions}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name="plate" required />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect name="brand" options={brandOptions} required />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name="model" required />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name="year" />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect name="color" options={colorOptions} required />
          </Col>
          <Col xs={24} sm={8} lg={8} align="start">
            <JarvislySwitch
              label="have_other_drivers"
              name="otherDrivers"
              //   checked={reminder}
              //   onChange={setReminder}
              disabled={false}
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name="tagNumber" label="tag_number" />
          </Col>
          <Col xs={24} sm={8} lg={16}>
            <JarvislyInput name="observation" />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={16}>
            <JarvislyInput name="observation" />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default VehiclesRecordTab;
