import React, { forwardRef, useContext, useEffect } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { Col, ColorPicker, Modal, Row, Form } from 'antd';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showParametersModal,
    setShowParametersModal,
    isDisabled,
    setSelectedRecord,
    selectedRecord,
  } = props;

  // local variables I ----------------------------------------------------------

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showParametersModal) {
      form?.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord, showParametersModal, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowParametersModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={translateX('new_sector')}
        destroyOnClose={true}
        open={showParametersModal}
        // width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (form.submit())}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Row gutter={ROW_GUTTER}>
          {/* NAME */}
          <Col xs={20}>
            <JarvislyInput name="label" label="name" disabled={false}/>
          </Col>
          <Col xs={2}>
            <Form.Item
              name="backColor"
              // style={{ width: '0%', marginTop: '25px' }}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '25px',
              }}
              required={true}
            >
              <ColorPicker
                disabled={isDisabled}
                value={form?.getFieldValue('backColor')}
                onChange={backColor => form.setFieldsValue({ backColor })}
                defaultFormat="hex"
                size="small"
              />
            </Form.Item>
          </Col>
          {/* NAME */}
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const SectorsModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    setShowParametersModal,
    selectedRecord,
    refreshParameters,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onConfirm = async ({ body }) => {
    // color
    if (body?.backColor?.toHexString) body.backColor = body.backColor?.toHexString();

    body._id = selectedRecord?._id;

    const axiosOptions = {
      url: `/v1/iot-device-parameters/add-to-set/${parameters?._id}/$push/sectors`,
      data: body,
    };

    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.put(axiosOptions);

    setShowParametersModal(false);
    refreshParameters(true);

    return body;
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        name="form-parameters-sectors"
        document={selectedRecord}
        requiredFields={['label']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        autoFocus="label"
        abortComponentSave={true}
      >
        <ModalComponent {...props} />
      </JarvislyFormProvider>
    </>
  );
});

export default SectorsModal;
