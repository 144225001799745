// COMPONENT *******************************************************************
// *****************************************************************************
import { Card, Tabs } from 'antd';
import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import UnitsRecordTab from './records/UnitsRecordTab';
import UnitsTitularResidentTab from './titularResident/TitularResidentTab';
import HabitationTab from './habitation/HabitationTab';
import JarvislyDependentsIndex from 'components/jarvisly-components/forms/JarvislyDependentsIndex';
import JarvislyDependentsModal from 'components/jarvisly-components/forms/modals/JarvislyDependentsModal';
import JarvislyVehiclesIndex from 'components/jarvisly-components/forms/JarvislyVehiclesIndex';
import JarvislyVehiclesModal from 'components/jarvisly-components/forms/modals/JarvislyVehiclesModal';
import JarvislyBicyclesIndex from 'components/jarvisly-components/forms/JarvislyBicyclesIndex';
import JarvislyBicyclesModal from 'components/jarvisly-components/forms/modals/JarvislyBicyclesModal';
import JarvislyPetsModal from 'components/jarvisly-components/forms/modals/JarvislyPetsModal';
import JarvislyPetsIndex from 'components/jarvisly-components/forms/JarvislyPetsIndex';
import AgreementTab from './agreement/AgreementTab';

const UnitsForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus, mode } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [
    initTab,
    'titularResident',
    'habitation',
    'dependents',
    'vehicles',
    'bicycles',
    'pets',
    'agreement',
  ];

  const vehiclesModalRef = useRef(null);
  const dependentsModalRef = useRef(null);
  const bicyclesModalRef = useRef(null);
  const petsModalRef = useRef(null);

  const titularTabRef = useRef(null);
  const habitationTabRef = useRef(null);
  const agreementTabRef = useRef(null);

  const dependentsTabProps = {
    ...props,
    showDependentsModal: () => dependentsModalRef.current?.showModal(),
    setShowDependentsModal: value =>
      dependentsModalRef.current?.setShowModal(value),
    selectedRecord: dependentsModalRef.current?.selectedRecord,
    setSelectedRecord: record =>
      dependentsModalRef.current?.setSelectedRecord(record),
  };

  const vehiclesTabProps = {
    ...props,
    showVehiclesModal: () => vehiclesModalRef.current?.showModal(),
    setShowVehiclesModal: value =>
      vehiclesModalRef.current?.setShowModal(value),
    selectedRecord: vehiclesModalRef.current?.selectedRecord,
    setSelectedRecord: record =>
      vehiclesModalRef.current?.setSelectedRecord(record),
  };

  const bicyclesTabProps = {
    ...props,
    showBicyclesModal: () => bicyclesModalRef.current?.showModal(),
    setShowBicyclesModal: value =>
      bicyclesModalRef.current?.setShowModal(value),
    selectedRecord: bicyclesModalRef.current?.selectedRecord,
    setSelectedRecord: record =>
      bicyclesModalRef.current?.setSelectedRecord(record),
  };

  const petsTabProps = {
    ...props,
    showPetsModal: () => petsModalRef.current?.showModal(),
    setShowPetsModal: value => petsModalRef.current?.setShowModal(value),
    selectedRecord: petsModalRef.current?.selectedRecord,
    setSelectedRecord: record =>
      petsModalRef.current?.setSelectedRecord(record),
  };

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    return body;
  };

  const onAfterSave = async ({ doc, body }) => {
    if (!doc || !_id) return;
    try {
      if (titularTabRef?.current) {
        titularTabRef.current.onSaveTab(body);
      }

      if (habitationTabRef?.current) {
        habitationTabRef.current.onSaveTab(body);
      }

      if (agreementTabRef?.current) {
        agreementTabRef.current.onSaveTab(body);
      }
    } catch(error) {
      console.error(error);
    }
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* DEPENDENTS MODAL WRAPPER */}
      <JarvislyDependentsModal {...props} ref={dependentsModalRef} />
      {/* DEPENDENTS MODAL WRAPPER */}

      {/* VEHICLES MODAL WRAPPER */}
      <JarvislyVehiclesModal {...props} ref={vehiclesModalRef} />
      {/* VEHICLES MODAL WRAPPER */}

      {/* VEHICLES MODAL WRAPPER */}
      <JarvislyBicyclesModal {...props} ref={bicyclesModalRef} />
      {/* VEHICLES MODAL WRAPPER */}

      {/* VEHICLES MODAL WRAPPER */}
      <JarvislyPetsModal {...props} ref={petsModalRef} />
      {/* VEHICLES MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-units"
        document={document}
        autoFocus="cpf"
        requiredFields={['name']}
        // showSubmitButton={tab !== 'responsable'}
        setTab={setTab}
        onSave={onSave}
        onAfterSave={onAfterSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <>
            <JarvislyTabProvider tab={initTab}>
              <UnitsRecordTab {...props} activeTab={tab} />
            </JarvislyTabProvider>
          </>
        ),
      },
      {
        label: translateX('titular_resident'),
        key: 'titularResident',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="titularResident">
            <UnitsTitularResidentTab
              {...props}
              ref={titularTabRef}
              activeTab={tab}
            />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('habitation'),
        key: 'habitation',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="habitation">
            <HabitationTab {...props} ref={habitationTabRef} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('dependents'),
        key: 'dependents',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="dependents">
            <Card className="cad-with-body-no-padding">
              <JarvislyDependentsIndex
                {...dependentsTabProps}
                activeTab={tab}
              />
            </Card>
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('vehicles'),
        key: 'vehicles',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="vehicles">
            <Card className="cad-with-body-no-padding">
              <JarvislyVehiclesIndex {...vehiclesTabProps} activeTab={tab} />
            </Card>
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('bicycles'),
        key: 'bicycles',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="bicycles">
            <Card className="cad-with-body-no-padding">
              <JarvislyBicyclesIndex {...bicyclesTabProps} activeTab={tab} />
            </Card>
          </JarvislyTabProvider>
        ),
      },
      {
        label: 'Pets',
        key: 'pets',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="pets">
            <Card className="cad-with-body-no-padding">
              <JarvislyPetsIndex {...petsTabProps} activeTab={tab} />
            </Card>
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('conclusion'),
        key: 'agreement',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="agreement">
            <AgreementTab {...props} ref={agreementTabRef} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};

export default UnitsForm;
