import React from 'react';
import { Card, Avatar, Spin, Flex } from 'antd';
import CustomStatistic from '../CustomStatistic';

const DataDisplayWidget = props => {
  const {
    size,
    value,
    title,
    icon,
    color,
    avatarSize = 50,
    vertical = false,
    loading = false,
  } = props;

  const customStatisticProps = { size, value, title };

  return (
    <Card>
      <Flex justify="center" align="center">
        <Spin spinning={loading}>
          <Flex justify="center" align="center" vertical={vertical}>
            <Avatar
              size={avatarSize}
              shape="square"
              icon={icon}
              className={`mt-2 ant-avatar-${color}`}
            />
            <div className={vertical ? 'mt-3 text-center' : 'ml-3'}>
              <CustomStatistic {...customStatisticProps} />
            </div>
          </Flex>
        </Spin>
      </Flex>
    </Card>
  );
};

export default DataDisplayWidget;
