import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import {
  parseCpfToDisplay,
  setLocale,
  translateWord,
  translateX,
} from 'utils/helpers';
import dayjs from 'dayjs';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import React from 'react';
import JarvislyEntityProfileTag from 'components/jarvisly-components/forms/Items/JarvislyEntityProfileTag';
import MULTI_CUSTOMERS_MODULE from '../customers';

const MultiCustomersList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const dataProfileFilter = buildDocumentStatusFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options =
      MULTI_CUSTOMERS_MODULE?.initialRequest?.filterOptions?.dataProfile || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateWord('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('document'),
        dataIndex: '_metadata.counter',
        ...columnSorter('_metadata.counter'),
        ...columnSearch('_metadata.counter'),
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateWord('name'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                title={record?.name? record?.name : record?.fullName}
                subtitle={parseCpfToDisplay(record?.cpf)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateWord('address'),
        dataIndex: '__address',
        render: (_, record) => (
          <>
            <div>{record?.__address}</div>
            <div>{record?.__city}</div>
          </>
        ),
      },

      {
        title: translateWord('profile'),
        dataIndex: 'dataProfile',
        ...columnFilters('dataProfile', dataProfileFilter),
        // ...columnSorter('dataProfile'),
        width: 100,
        className: 'text-center',
        render: (_, record) => (
          <JarvislyEntityProfileTag
            record={record}
            space="left"
            className="mb-1"
          />
        ),
      },

      {
        title: translateWord('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt'),
        width: 160,
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              // moment(record._metadata.audit.updatedAt).format('L LT')}
              dayjs(record._metadata.audit.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default MultiCustomersList;
