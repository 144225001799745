import { translateX } from 'utils/helpers';
import axiosService from './axiosService';

const appsindicoService = {};
appsindicoService.buildBlockOptions = parameters => {};

appsindicoService.profileOptions = parameters => {
  return [{ label: 'option1', value: '66981ab979aa1ee1007ba792' }];
};
appsindicoService.buildBlockOptions = parameters => {
  return [{ label: 'option1', value: '66981b2c79aa1ee1007ba799' }];
};

appsindicoService.buildCarBrandOptions = parameters => {
  const carBrands = [
    'Audi',
    'BMW',
    'Chery',
    'Chevrolet',
    'Chrysler',
    'Citroen',
    'Dodge',
    'Fiat',
    'Ford',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jac Motors',
    'Jaguar',
    'Jeep',
    'Kia',
    'Land Rover',
    'Lexus',
    'Lifan',
    'Mercedes Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Peugeot',
    'Porsche',
    'Ram',
    'Renault',
    'Rolls Royce',
    'Subaru',
    'Suzuki',
    'Troller',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ];

  return carBrands
    .map(car => ({
      value: car.toLowerCase().replace(' ', '_'),
      label: car,
    }))
    .concat({
      value: 'not_listed',
      label: translateX('not_listed'),
    });
};

// Função para construir opções de marcas de motocicleta
appsindicoService.buildMotorcycleBrandOptions = parameters => {
  const motorcycleBrands = [
    'Honda',
    'Yamaha',
    'Suzuki',
    'Dafra',
    'BMW',
    'Harley Davidson',
    'Kawasaki',
  ];

  return motorcycleBrands
    .map(motorcycle => ({
      value: motorcycle.toLowerCase().replace(' ', '_'),
      label: motorcycle,
    }))
    .concat({
      value: 'not_listed',
      label: translateX('not_listed'),
    });
};

// Função para construir opções de tipo
appsindicoService.buildTypeVehiclesOptions = parameters => [
  { value: 'car', label: translateX('car') },
  { value: 'motorcycle', label: translateX('motorcycle') },
];

appsindicoService.buildBicycleBrandOptions = parameters => [
  { value: 'Audax', label: translateX('Audax') },
  { value: 'Bianchi', label: translateX('Bianchi') },
  { value: 'Cannondale', label: translateX('Cannondale') },
  { value: 'Caloi', label: translateX('Caloi') },
  { value: 'Colnago', label: translateX('Colnago') },
  { value: 'Giant', label: translateX('Giant') },
  { value: 'GT Bicycles', label: translateX('GT Bicycles') },
  { value: 'Houston', label: translateX('Houston') },
  { value: 'KHS', label: translateX('KHS') },
  { value: 'Lapierre', label: translateX('Lapierre') },
  { value: 'Merida', label: translateX('Merida') },
  { value: 'Monark', label: translateX('Monark') },
  { value: 'Santa Cruz Bicycles', label: translateX('Santa Cruz Bicycles') },
  { value: 'Scott', label: translateX('Scott') },
  { value: 'Sense', label: translateX('Sense') },
  { value: 'Specialized', label: translateX('Specialized') },
  { value: 'Soul Cycles', label: translateX('Soul Cycles') },
  { value: 'Sundown', label: translateX('Sundown') },
  { value: 'Trek', label: translateX('Trek') },
  { value: 'Yeti Cycles', label: translateX('Yeti Cycles') },
  { value: 'not_listed', label: translateX('not_listed') },
];

appsindicoService.buildColorOptions = parameters => {
  return [
    {
      value: 'beige',
      label: translateX('beige'),
    },
    {
      value: 'black',
      label: translateX('black'),
    },
    {
      value: 'blue',
      label: translateX('blue'),
    },
    {
      value: 'bronze',
      label: translateX('bronze'),
    },
    {
      value: 'brown',
      label: translateX('brown'),
    },
    {
      value: 'burgundy',
      label: translateX('burgundy'),
    },
    {
      value: 'caramel',
      label: translateX('caramel'),
    },
    {
      value: 'champagne',
      label: translateX('champagne'),
    },
    {
      value: 'gray',
      label: translateX('gray'),
    },
    {
      value: 'green',
      label: translateX('green'),
    },
    {
      value: 'golden',
      label: translateX('golden'),
    },
    {
      value: 'orange',
      label: translateX('orange'),
    },
    {
      value: 'pink',
      label: translateX('pink'),
    },
    {
      value: 'purple',
      label: translateX('purple'),
    },
    {
      value: 'red',
      label: translateX('red'),
    },
    {
      value: 'silver',
      label: translateX('silver'),
    },
    {
      value: 'turquoise',
      label: translateX('turquoise'),
    },
    {
      value: 'white',
      label: translateX('white'),
    },
    {
      value: 'wine',
      label: translateX('wine'),
    },
    {
      value: 'yellow',
      label: translateX('yellow'),
    },
    {
      value: 'not_listed',
      label: translateX('not_listed'),
    },
  ];
};

appsindicoService.buildPetsColorOptions = parameters => {
  return [
    {
      value: 'black',
      label: translateX('black'),
    },
    {
      value: 'brown',
      label: translateX('brown'),
    },
    {
      value: 'beige',
      label: translateX('beige'),
    },
    {
      value: 'caramel',
      label: translateX('caramel'),
    },
    {
      value: 'checkered',
      label: translateX('checkered'),
    },
    {
      value: 'gray',
      label: translateX('gray'),
    },
    {
      value: 'orange',
      label: translateX('orange'),
    },
    {
      value: 'peach',
      label: translateX('peach'),
    },
    {
      value: 'sand',
      label: translateX('sand'),
    },
    {
      value: 'spotted',
      label: translateX('spotted'),
    },
    {
      value: 'white',
      label: translateX('white'),
    },
    {
      value: 'yellow',
      label: translateX('yellow'),
    },
    { value: 'not_listed', label: translateX('not_listed') },
  ];
};

appsindicoService.buildSpecieOptions = parameters => {
  return [
    {
      value: 'puppy',
      label: translateX('puppy'),
    },
    {
      value: 'cat',
      label: translateX('cat'),
    },
    {
      value: 'bird',
      label: translateX('bird'),
    },
    {
      value: 'hamster',
      label: translateX('hamster'),
    },
    {
      value: 'not_listed',
      label: translateX('not_listed'),
    },
  ];
};

appsindicoService.buildGenderOptions = parameters => {
  return [
    {
      value: 'female',
      label: translateX('female'),
    },
    {
      value: 'male',
      label: translateX('male'),
    },
    {
      value: 'prefer_not_inform',
      label: translateX('prefer_not_inform'),
    },
  ];
};

appsindicoService.buildRimOptions = parameters => [
  { value: '12', label: '12' },
  { value: '16', label: '16' },
  { value: '20', label: '20' },
  { value: '24', label: '24' },
  { value: '26', label: '26' },
  { value: '27.5', label: '27.5' },
  { value: '29', label: '29' },
];

appsindicoService.buildHabitationProfileOptions = parameters => [
  { value: 'owners', label: translateX('owners') },
  { value: 'tenant', label: translateX('tenant') },
  { value: 'non_tenant_resident', label: translateX('non_tenant_resident') },
];

appsindicoService.buildContractOptions = parameters => [
  {
    value: 'rental_through_agency',
    label: translateX('rental_through_agency'),
  },
  { value: 'rental_direct_owner', label: translateX('rental_direct_owner') },
];

appsindicoService.buildkinshipOptions = parameters => [
  {
    value: 'husband_wife',
    label: translateX('husband_wife'),
  },
  { value: 'father_mother', label: translateX('father_mother') },
  { value: 'son_daughter', label: translateX('son_daughter') },
  { value: 'brother_sister', label: translateX('brother_sister') },
  { value: 'grandfather_mother', label: translateX('grandfather_mother') },
  { value: 'grandson_daughter', label: translateX('grandson_daughter') },
  {
    value: 'great_grandson_daughter',
    label: translateX('great_grandson_daughter'),
  },
  {
    value: 'great_grandfather_mother',
    label: translateX('great_grandfather_mother'),
  },
  { value: 'nephew_niece', label: translateX('nephew_niece') },
  { value: 'uncle_aunt', label: translateX('uncle_aunt') },
  { value: 'cousin', label: translateX('cousin') },
  {
    value: 'brother_sister_in_law',
    label: translateX('brother_sister_in_law'),
  },
  { value: 'father_mother_in_law', label: translateX('father_mother_in_law') },
  { value: 'son_daughter_in_law', label: translateX('son_daughter_in_law') },
  { value: 'stepfather_mother', label: translateX('stepfather_mother') },
  { value: 'stepson_daughter', label: translateX('stepson_daughter') },
  { value: 'boyfriend_girlfriend', label: translateX('boyfriend_girlfriend') },
  { value: 'fiance_fiancee', label: translateX('fiance_fiancee') },
  { value: 'friend', label: translateX('friend') },
  { value: 'spouse_husband', label: translateX('spouse_husband') },
  { value: 'other', label: translateX('other') },
];

appsindicoService.buildCatOptions = parameters => [
  { value: 'siamese', label: translateX('siamese') },
  { value: 'persa', label: translateX('persa') },
  { value: 'maine_coon', label: translateX('maine_coon') },
  { value: 'ragdoll', label: translateX('ragdoll') },
  { value: 'siamese_snowshoe', label: translateX('siamese_snowshoe') },
  { value: 'sphynx', label: translateX('sphynx') },
  { value: 'british_shorthair', label: translateX('british_shorthair') },
  { value: 'munchkin', label: translateX('munchkin') },
  { value: 'exótico', label: translateX('exótico') },
  { value: 'abyssinian', label: translateX('abyssinian') },
  { value: 'mongrel', label: translateX('mongrel') },
];

appsindicoService.buildPuppyOptions = parameters => [
  { value: 'akita_inu', label: translateX('akita_inu') },
  {
    value: 'american_cocker_spaniel',
    label: translateX('american_cocker_spaniel'),
  },
  {
    value: 'american_pit_bull_terrier',
    label: translateX('american_pit_bull_terrier'),
  },
  { value: 'argentinian_dogo', label: translateX('argentinian_dogo') },
  { value: 'australian_shepherd', label: translateX('australian_shepherd') },
  { value: 'basset_hound', label: translateX('basset_hound') },
  { value: 'basenji', label: translateX('basenji') },
  { value: 'beagle', label: translateX('beagle') },
  { value: 'beauce_shepherd', label: translateX('beauce_shepherd') },
  { value: 'bernese_mountain_dog', label: translateX('bernese_mountain_dog') },
  { value: 'bichon_frise', label: translateX('bichon_frise') },
  { value: 'border_collie', label: translateX('border_collie') },
  { value: 'bordeaux_dog', label: translateX('bordeaux_dog') },
  { value: 'boxer', label: translateX('boxer') },
  { value: 'cane_corso', label: translateX('cane_corso') },
  {
    value: 'cavalier_king_charles_spaniel',
    label: translateX('cavalier_king_charles_spaniel'),
  },
  { value: 'chihuahua', label: translateX('chihuahua') },
  { value: 'chow_chow', label: translateX('chow_chow') },
  { value: 'dachshund', label: translateX('dachshund') },
  { value: 'dalmatian', label: translateX('dalmatian') },
  { value: 'doberman_pinscher', label: translateX('doberman_pinscher') },
  { value: 'english_bulldog', label: translateX('english_bulldog') },
  { value: 'french_bulldog', label: translateX('french_bulldog') },
  { value: 'german_mastiff', label: translateX('german_mastiff') },
  { value: 'german_shepherd', label: translateX('german_shepherd') },
  { value: 'german_spitz', label: translateX('german_spitz') },
  { value: 'golden_retriever', label: translateX('golden_retriever') },
  { value: 'irish_setter', label: translateX('irish_setter') },
  { value: 'jack_russell_terrier', label: translateX('jack_russell_terrier') },
  { value: 'kuvasz', label: translateX('kuvasz') },
  { value: 'labrador_retriever', label: translateX('labrador_retriever') },
  { value: 'lhasa_apso', label: translateX('lhasa_apso') },
  {
    value: 'maremmano_abruzzese_shepherd',
    label: translateX('maremmano_abruzzese_shepherd'),
  },
  { value: 'maltese', label: translateX('maltese') },
  { value: 'miniature_schnauzer', label: translateX('miniature_schnauzer') },
  { value: 'neapolitan_mastiff', label: translateX('neapolitan_mastiff') },
  { value: 'pinscher', label: translateX('pinscher') },
  { value: 'pomeranian', label: translateX('pomeranian') },
  { value: 'poodle', label: translateX('poodle') },
  { value: 'rottweiler', label: translateX('rottweiler') },
  { value: 'shar_pei', label: translateX('shar_pei') },
  { value: 'shetland_sheepdog', label: translateX('shetland_sheepdog') },
  { value: 'shih_tzu', label: translateX('shih_tzu') },
  { value: 'siberian_husky', label: translateX('siberian_husky') },
  {
    value: 'staffordshire_bull_terrier',
    label: translateX('staffordshire_bull_terrier'),
  },
  { value: 'weimaraner', label: translateX('weimaraner') },
  { value: 'welsh_corgi_pembroke', label: translateX('welsh_corgi_pembroke') },
  {
    value: 'west_highland_white_terrier',
    label: translateX('west_highland_white_terrier'),
  },
  { value: 'yorkshire_terrier', label: translateX('yorkshire_terrier') },
  { value: 'mongrel', label: translateX('mongrel') },
];

appsindicoService.buildBirdOptions = parameters => [
  { value: 'cockatiels', label: translateX('cockatiels') },
  { value: 'canaries', label: translateX('canaries') },
  { value: 'budgerigars', label: translateX('budgerigars') },
  { value: 'toucans', label: translateX('toucans') },
  { value: 'macaws', label: translateX('macaws') },
  { value: 'parrots', label: translateX('parrots') },
  { value: 'mandarins', label: translateX('mandarins') },
];

appsindicoService.buildSizeBrandsOptions = parameters => [
  { value: 'small', label: translateX('small') },
  { value: 'medium', label: translateX('medium') },
  { value: 'large', label: translateX('large') },
];

appsindicoService.buildSizeBrandsOptions = parameters => [
  { value: 'small', label: translateX('small') },
  { value: 'medium', label: translateX('medium') },
  { value: 'large', label: translateX('large') },
];

appsindicoService.buildFireBrigadeOptions = parameters => [
  {
    value: 'resident_with_walking_difficulty',
    title: translateX('special_attention'),
    description: translateX('special_attention_description'),
    checked: translateX('resident_with_walking_difficulty') || false,
  },
  {
    value: 'authorize_open_the_door',
    title: translateX('emergency_case'),
    description: translateX('emergency_case_description'),
    checked: translateX('authorize_open_the_door') || false,
  },
];
appsindicoService.buildAgreementOptions = parameters => [
  {
    value: 'declaration',
    title: translateX('agreement_declaration'),
    description: translateX('agreement_declaration_description'),
    // checked: _data?.agreement?.declaration || false,
  },
  {
    value: 'commitment',
    title: translateX('agreement_commitment'),
    description: translateX('agreement_commitment_description'),
    // checked: _data?.agreement?.commitment || false,
  },
  {
    value: 'aware',
    title: translateX('agreement_aware'),
    description: translateX('agreement_aware_description'),
    // checked: _data?.agreement?.aware || false,
  },
];

appsindicoService.buildTranslationsOptions = parameters => [
  {
    yes: translateX('yes'),
    no: translateX('no'),
    forceFinishTitle: translateX('required_fields'),
    forceFinishMessage: translateX('send_update_without_agreement'),
    update_sent_with_success: translateX('update_sent_with_success'),
  },
];

// appsindicoService.removeDependent = async (unitId, data) => {
//   const agreementOptions = {
//     url: `/v1/units/add-to-set/${unitId}/$pull/dependentsIds`,
//     data: { _id: data._id }
//   };
//   const [dependentsIds] = await axiosService.put(agreementOptions);
//   if(dependentsIds) {
//     axiosService
//     .delete({
//       url: 'v1/dependents',
//       _id: data._id,
//       headers: { 'x-forcepermanentlydeletation': true },
//     })
//     .then();
//   }

// };

appsindicoService.removeArrayId = async (
  data,
  urlPut,
  putId,
  arrayIds,
  urlDelete,
) => {
  const axiosInitialOptions = {
    url: `${urlPut}/add-to-set/${putId}/$pull/${arrayIds}`,
    data: { _id: data._id },
  };
  const [arraysIds] = await axiosService.put(axiosInitialOptions);
  if (arraysIds) {
    axiosService
      .delete({
        url: `${urlDelete}`,
        _id: data._id,
        headers: { 'x-forcepermanentlydeletation': true },
      })
      .then();
  }
};


export default appsindicoService;
