import React from 'react';
import { Button, Grid, Row } from 'antd';

import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import utils from '../../utils';
import AppBreadcrumb from '../layout-components/AppBreadcrumb';
import Icon from '../util-components/Icon';
import { translateX } from '../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { ACCOUNTS_ROUTE } from '../../modules/app-views/shared-modules/account/account';
import app from '../../configs/ConfigIndex';

const { useBreakpoint } = Grid;
const Wrapper = styled('div')`
  background-color: ${app.backgroundColor};
  z-index: 998;

  ${props =>
    props.cover === 'content'
      ? `
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	`
      : ''}

  ${props =>
    props.cover === 'page'
      ? `
		position: fixed;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	`
      : ''}
`;

const ModuleBlocked = props => {
  const { align = 'center', cover = 'content' } = props;

  const { navCollapsed, navType } = useSelector(state => state.theme);
  const { selectedSubscription } = useSelector(state => state.authSlice);
  const { selectedModule } = useSelector(state => state.moduleSlice);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const navigate = useNavigate();

  let paddingLeft = 0;
  if (navType === 'SIDE' && !isMobile) {
    paddingLeft = navCollapsed ? 80 : 250;
  }

  const goto = () =>
    // navigate('/app/account/settings', { replace: true });
    // navigate(`${ACCOUNTS_MAIN_ROUTE.path}?tab=organizations`);
    navigate(`${ACCOUNTS_ROUTE}?tab=organizations`);

  if (
    selectedModule &&
    !selectedModule.noBlockScreenWithAppNotConfigured &&
    !selectedSubscription?._id
  ) {
    return (
      <Wrapper
        className={`${align ? `text-${align}` : ''}`}
        style={{
          paddingLeft,
        }}
        cover={cover}
      >
        <div>
          <Row justify="center">
            <h3 className="mb-0 font-weight-semibold">
              {selectedModule?.icon && (
                <Icon type={selectedModule.icon} className="mr-2" />
              )}
              {selectedModule?.title && translateX(selectedModule.title)}
            </h3>
          </Row>
          <Row justify="center">
            <AppBreadcrumb minWidth={0} />
          </Row>
          <div className="p-4">
            {translateX('before_use_create_an_organization')}
          </div>
          <div className="p-1">
            <Button type="primary" onClick={goto}>
              {translateX(app.PROFILE.REGISTER_ORGANIZATION)}
            </Button>
          </div>
        </div>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default ModuleBlocked;
