import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import publicRoutes from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import AppRoute from './AppRoute';
import PublicRoute from './PublicRoute';
import { APPMULTI_OPERATORS_ROUTE } from 'modules/app-views/appmulti/operators/operators';
import NoRouteSet from 'components/jarvisly-components/others-old/NoRouteSet';
import WelcomeIndex from 'modules/app-views/shared-modules/home/WelcomeIndex';
import NoModuleFound from 'components/jarvisly-components/others-old/NoModuleFound';
import ListIndex from 'components/jarvisly-module/List/ListIndex';
import FormIndex from 'components/jarvisly-module/Form/FormIndex';
import { APPMULTI_LEADS_ROUTE } from 'modules/app-views/appmulti/leads/leads';
import app from 'configs/ConfigIndex';
import { MODULES } from 'configs/InitializeApp';
import { APPMULTI_QUOTATIONS_ROUTE } from 'modules/app-views/appmulti/quotations/quotations';
import { APPMULTI_PLANS_ROUTE } from 'modules/app-views/appmulti/plans/plans';
import {
  ACCOUNTS_ORGANIZATIONS_ROUTE,
  ACCOUNTS_ROUTE,
} from '../modules/app-views/shared-modules/account/account';
import AccountIndex from '../modules/app-views/shared-modules/account/AccountIndex';
import { APPMULTI_CUSTOMERS_ROUTE } from '../modules/app-views/appmulti/customers-persons/customers';
import MultiQuotationsIndex from '../modules/app-views/appmulti/quotations/List/ListIndex';
import { APPMULTI_ORDERS_ROUTE } from '../modules/app-views/appmulti/orders/orders';
import { RECEIVABLES_ROUTE } from '../modules/app-views/applekkus/receivables/receivables';
import { CRM_ROUTE } from '../modules/app-views/applekkus/crm/crm';
import { APPDEDALO_AGENDAS_ROUTE } from '../modules/app-views/appdedalo/agendas/agendas';
import { APPTEXANSPORTS_ATHLETES_ROUTE } from '../modules/app-views/apptexansports/athletes/athletes';
import { COUPONS_ROUTE } from 'modules/app-views/appmulti/coupons/coupons';
import { PRODUCTS_ROUTE } from '../modules/app-views/applekkus/products/products';
import { ORDERS_ROUTE } from 'modules/app-views/applekkus/orders/orders';
import { APPSINDICO_UNITS_ROUTE } from 'modules/app-views/appsindico/units/units';
import { APPVEERTICAL_IOTDEVICES_ROUTE } from 'modules/app-views/appveertical/iotDevices/iotDevices';
import { CUSTOMERS_ROUTE } from '../modules/app-views/applekkus/customers/customers';
import { APPSINDICO_VEHICLES_ROUTE } from 'modules/app-views/appsindico/Vehicles/vehicles';

export const modulesRoutes = ['app/home/welcome'];

app?.MODULES?.forEach(m => {
  if (m && MODULES?.[m]?.routes) {
    modulesRoutes.push(...MODULES[m].routes);
  }
});

app?.MENU_NAVIGATION?.map(nav => {
  return validateRoute(nav?.submenu);
});

let dynamicRoutes = [];

function validateRoute(submenu) {
  if (!submenu || !Array.isArray(submenu)) return;

  submenu?.map(sm => {
    if (sm?.submenu) {
      validateRoute(sm.submenu);
    } else {
      if (!modulesRoutes.includes(sm.path)) {
        modulesRoutes.push(sm.path);
      }
    }

    return sm;
  });
}

// only exists components independent of routes
const componentsByPath = {
  // core routes
  'fallback.noRoute': NoRouteSet,
  'app/home/welcome': WelcomeIndex,
  'app/scaffolding/foo': NoModuleFound,

  // "app/account/settings/organization": React.lazy(
  //   () => import("views/app-views/account/settings/tabs/subscriptions/Form/OrganizationForm.jsx")),

  [ACCOUNTS_ROUTE]: AccountIndex,
  [`${ACCOUNTS_ORGANIZATIONS_ROUTE}/:_id`]: FormIndex,

  [CRM_ROUTE]: ListIndex,
  [`${CRM_ROUTE}/:_id`]: FormIndex,

  [ORDERS_ROUTE]: ListIndex,
  [`${ORDERS_ROUTE}/:_id`]: FormIndex,

  [RECEIVABLES_ROUTE]: ListIndex,
  [`${RECEIVABLES_ROUTE}/:_id`]: FormIndex,

  // [QUOTATIONS_ROUTE]: ListIndex,
  // [`${QUOTATIONS_ROUTE}/:_id`]: FormIndex,

  [APPMULTI_CUSTOMERS_ROUTE]: ListIndex,
  [`${APPMULTI_CUSTOMERS_ROUTE}/:_id`]: FormIndex,

  [COUPONS_ROUTE]: ListIndex,
  [`${COUPONS_ROUTE}/:_id`]: FormIndex,

  // appmulti
  [APPMULTI_OPERATORS_ROUTE]: ListIndex,
  [`${APPMULTI_OPERATORS_ROUTE}/:_id`]: FormIndex,

  [APPMULTI_PLANS_ROUTE]: ListIndex,
  [`${APPMULTI_PLANS_ROUTE}/:_id`]: FormIndex,

  [APPMULTI_LEADS_ROUTE]: ListIndex,
  [`${APPMULTI_LEADS_ROUTE}/:_id`]: FormIndex,

  [APPMULTI_QUOTATIONS_ROUTE]: MultiQuotationsIndex,

  [APPMULTI_ORDERS_ROUTE]: ListIndex,
  [`${APPMULTI_ORDERS_ROUTE}/:_id`]: FormIndex,

  [PRODUCTS_ROUTE]: ListIndex,
  [`${PRODUCTS_ROUTE}/:_id`]: FormIndex,

  // [APPVEERTICAL_DEVICES_ROUTE]: ListIndex,
  // [`${APPVEERTICAL_DEVICES_ROUTE}/:_id`]: FormIndex,

  // appdedalo
  [APPDEDALO_AGENDAS_ROUTE]: ListIndex,
  [`${APPDEDALO_AGENDAS_ROUTE}/:_id`]: FormIndex,

  // apptexansports
  [APPTEXANSPORTS_ATHLETES_ROUTE]: ListIndex,
  [`${APPTEXANSPORTS_ATHLETES_ROUTE}/:_id`]: FormIndex,

  // appsindico
  [APPSINDICO_UNITS_ROUTE]: ListIndex,
  [`${APPSINDICO_UNITS_ROUTE}/:_id`]: FormIndex,

  [APPSINDICO_VEHICLES_ROUTE]: ListIndex,
  [`${APPSINDICO_VEHICLES_ROUTE}/:_id`]: FormIndex,

  // appveertical
  [APPVEERTICAL_IOTDEVICES_ROUTE]: ListIndex,
  [`${APPVEERTICAL_IOTDEVICES_ROUTE}/:_id`]: FormIndex,

  // applekkus
  [CUSTOMERS_ROUTE]: ListIndex,
  [`${CUSTOMERS_ROUTE}/:_id`]: FormIndex,
};

const Routes = props => {
  modulesRoutes.map(r => {
    const routeNotAdded = dynamicRoutes.findIndex(i => i?.path === r) === -1;

    if (routeNotAdded) {
      dynamicRoutes.push({
        key: r,
        path: r,
        // component: React.lazy(() => (componentsByPath[r] || componentsByPath['fallback.noRoute'])),
        component:
          componentsByPath?.[r] || componentsByPath['fallback.noRoute'],
        fallback: !componentsByPath?.[r],
      });
    }

    return r;
  });

  // useEffect(() => {
  //   setSelectedModule(location.pathname);
  // }, [location.pathname]);

  return (
    <RouterRoutes>
      {/* PROTECTED ROUTES */}
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate replace to={app.AUTHENTICATED_ENTRY} />}
        />

        {dynamicRoutes?.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={
                <AppRoute
                  {...props}
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      {/* PROTECTED ROUTES */}

      {/* PUBLIC ROUTES */}
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
      {/* PUBLIC ROUTES */}
    </RouterRoutes>
  );
};

export default Routes;
