import { useContext, useEffect, useState } from 'react';
import { Card, Flex, Modal, Row, Spin, Steps } from 'antd';
import {
  buildSteps,
  FormButton,
  JarvislyFormContext,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { clone, translateX } from 'utils/helpers';
import MultiQuotationsCheckoutStep1 from './Steps/CheckoutStep1';
import appService from 'services/appService';
import dayjs from 'dayjs';
import { MdFlightTakeoff } from 'react-icons/md';
import appmultiService from 'services/appmultiService';
import MultiQuotationsCheckoutStep2 from './Steps/CheckoutStep2';
import { CardPlan } from '../Components';
import MultiQuotationsCheckoutStep3 from './Steps/CheckoutStep3';
import Icon from 'components/util-components/Icon';

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const MultiQuotationCheckoutModal = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    record,
    documentContext,
    travellers,
    setTravellers,
  } = props;

  // local variables I ----------------------------------------------------------
  const { filter } = documentContext;
  const isDone = record?.status === 'generated_order';

  // component states -----------------------------------------------------------
  const [step, setStep] = useState(0);
  const [enabledSteps, setEnabledSteps] = useState([0]);

  const [plan, setPlan] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [updating, setUpdating] = useState(null);
  const [order, setOrder] = useState(null);
  const [adminAdjustment, setAdminAdjustment] = useState(null);
  const [adminDiscount, setAdminDiscount] = useState(null);
  const [emergencyContacts, setEmergencyContacts] = useState(null);

  const [paymentSelected, setPaymentSelected] = useState(null);
  const [creditCardInstallment, setCreditCardInstallment] = useState('1X');

  // local variables II ---------------------------------------------------------
  const stepsArr = buildStepsArray();
  const stepItems = buildSteps(stepsArr, step, isDone, enabledSteps);
  const isMobile = appService.isMobile();
  const isDisabled = !!order?._id;

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (filter?.passengers) setTravellers(buildTravellers());
  }, [filter?.passengers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (record && filter?.days && travellers.length > 0) {
      const days = filter.days;
      const p = appmultiService.decoratePlan({
        plan: clone(record),
        days,
        passengersArr: travellers,
        adminAdjustment,
        adminDiscount,
      });
      setPlan({ ...p });
    }
  }, [record, travellers]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowModal(false);
  };

  const onAfterClose = () => {
    if (order) {
      setOrder(null);
      setTravellers(buildTravellers(true));
      setStep(0);
      setPlan(null);
      setEnabledSteps([0]);
      setAdminAdjustment(null);
      setAdminDiscount(null);
      form?.resetFields();
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  const childProps = {
    ...props,
    step,
    setStep,
    setPlan,
    plan,
    filter,
    isMobile,
    setEnabledSteps,
    enabledSteps,
    isGenerating,
    setIsGenerating,
    setUpdating,
    updating,
    onClose,
    order,
    setOrder,
    isDisabled,
    setAdminAdjustment,
    setAdminDiscount,

    emergencyContacts,
    setEmergencyContacts,

    paymentSelected,
    setPaymentSelected,
    creditCardInstallment,
    setCreditCardInstallment,
  };

  return (
    <Modal
      closable={!isGenerating}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      width="auto"
      okText="Fechar"
      maskClosable={false}
      getContainer={false}
      open={showModal}
      onCancel={onClose}
      onOk={onClose}
      afterClose={onAfterClose}
      className="modal-content-padding-bottom-6"
    >
      <Spin spinning={isGenerating} size="large">
        
        {/* STEPS */}
        <Row gutter={ROW_GUTTER}>
          <Card className="w-100 no-shadow" bordered={false}>
            <Steps
              direction="horizontal"
              size="small"
              current={step}
              className="no-max-width-for-description"
              onChange={v => setStep(v)}
              items={stepItems}
            />
          </Card>
        </Row>
        {/* STEPS */}

        {/* SELECTED PLAN */}
        <Row gutter={ROW_GUTTER}>
          <CardPlan
            {...childProps}
            plan={plan}
            profile="checkout"
            title={
              <Flex align="center" style={{ color: 'darkcyan' }}>
                <div>
                  <Icon type={MdFlightTakeoff} />
                </div>
                <div style={{ marginLeft: 8 }}>{translateX('chosen_plan')}</div>
              </Flex>
            }
          />
        </Row>
        {/* SELECTED PLAN */}


        {/* STEP FORM */}
        <Row gutter={ROW_GUTTER}>
          {step === 0 ? <MultiQuotationsCheckoutStep1 {...childProps} /> : null}
          {step === 1 ? <MultiQuotationsCheckoutStep2 {...childProps} /> : null}
          {step === 2 ? <MultiQuotationsCheckoutStep3 {...childProps} /> : null}
        </Row>
        {/* </Title> */}

        {/* STEP FORM */}
      </Spin>

      {isDisabled ? (
        <Flex justify={isMobile ? 'center' : 'flex-end'} align="center">
          <FormButton
            title="close"
            type="default"
            style={{ width: 130 }}
            onClick={onClose}
          />
        </Flex>
      ) : null}
    </Modal>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildStepsArray() {
    return [
      {
        title: 'travelers',
        description: 'included_passengers',
      },
      {
        title: 'buyer',
        description: 'customer_data',
      },
      {
        title: 'finish',
        description: 'order_generation',
      },
    ];
  }

  function buildTravellers(forceClear = false) {
    // return
    const mockTravellers = [];

    if (!filter?.passengers) return mockTravellers;

    filter?.passengers?.map((age, idx) => {
      if (!isNaN(age) || forceClear) {
        const mockBirthdate = dayjs(filter.departure || dayjs()).subtract(
          age,
          'years',
        );

        let foundIdx = travellers?.findIndex(t => t?.key === idx);

        let mock = {
          key: idx,
          age,
          birthdate: mockBirthdate,
          cpf: '',
          gender: null,
          name: `${translateX('passenger')} ${idx + 1}`,
          backColor: '#b9bcc4',
          foreColor: '#ffffff',
        };

        if (foundIdx > -1) mock = travellers[foundIdx];

        mockTravellers.push(mock);
      }

      return age;
    });

    return mockTravellers || [];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiQuotationCheckoutModal;
