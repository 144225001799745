import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import { useEffect, useRef, useState } from 'react';
import { returnOnlyNumbers, translateX } from 'utils/helpers';
import { Card, Tabs } from 'antd';
import IotDevicesDeviceTab from './device/deviceTab';
import useTab from 'components/jarvisly-components/hooks/useTabHook';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import IotDevicesCustomerTab from './customer/customerTab';
import InstallationTab from './installation/installationTab';
import SettingsTab from './settings/settingsTab';
import entityService from 'services/entityService';
import ContactModal from './customer/contact/ContactModal';
import ContactTab from './customer/contact/ContactTab';

const IotDevicesForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, setModuleFocus, _id, mode } = props;

  // local variables I ---------------------------------------------------------
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'customer', 'installation', 'settings'];

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTab(availableTabs, 'channel');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [entityType, setEntityType] = useState('company');
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(false);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedCustomer,
    setSelectedCustomer,
    entityType,
    setEntityType,
    tab,
    showContactsModal,
    setShowContactsModal,
    selectedRecord,
    setSelectedRecord,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    // create or update entity
    let customer = body?.__customer || {};
    customer.dataProfile = 'customer';
    customer.dataType = 'company';
    customer.uniqueKey = returnOnlyNumbers(customer?.cnpj || customer?.cpf);

    customer = entityService.decorateEntity(
      {
        ...selectedCustomer,
        ...customer,
        _id: selectedCustomer?._id || customer?.selectedEntity?.key,
      },
      3,
      null,
      'customer',
    );

    customer = await entityService.upsertEntity(customer?._id, customer);

    body.customerId = customer?._id;

    delete body?.__customer;

    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* WRAPPER PARAMETER */}

      {/* WRAPPER PARAMETER */}

      {/* CONTACTS MODAL WRAPPER */}
      <ContactModal {...childProps} />
      {/* CONTACTS MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-iot-devices"
        document={document}
        initialValues={{ service: 'veertical' }}
        autoFocus="name"
        requiredFields={['channel', 'type', 'model', 'color', 'title']}
        // showSubmitButton={tab !== 'record'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('device'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <IotDevicesDeviceTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('customer'),
        key: 'customer',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={'customer'}>
            <IotDevicesCustomerTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('installation'),
        key: 'installation',
        hide: false,
        disabled: mode === 'add',
        children: <InstallationTab {...childProps} activeTab={tab} />,
      },

      {
        label: translateX('settings'),
        key: 'settings',
        hide: false,
        disabled: mode === 'add',
        children: <SettingsTab {...childProps} activeTab={tab} />,
      },
    ];
  }
};

export default IotDevicesForm;
