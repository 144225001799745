import {
  JarvislyDatePicker,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislySelect,
  JarvislyTitle,
} from '../../others/FormComponents';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, Modal, Row } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';
import appsindicoService from 'services/appsindicoService';
import dayjs from 'dayjs';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    record,
    showModal,
    setShowModal,
    afterClose,
    isDisabled,
    updating,
    document,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
    ? translateX('new_pet')
    : translateX('edit_pet');

  const { parameters } = useSelector(s => s.moduleSlice);
  const specieOptions = appsindicoService.buildSpecieOptions(parameters);
  const petsColorOptions = appsindicoService.buildPetsColorOptions(parameters);
  const catOptions = appsindicoService.buildCatOptions(parameters);
  const puppyOptions = appsindicoService.buildPuppyOptions(parameters);
  const buildBirdOptions = appsindicoService.buildBirdOptions(parameters);
  const sizeBrandsOptions =
    appsindicoService.buildSizeBrandsOptions(parameters);

  // component states -----------------------------------------------------------
  const [breedOptions, setBreedOptions] = useState([]);
  const [sizeBrands, setSizeBrands] = useState([]);
  const [showSize, setShowSize] = useState(false);
  const [showBreed, setShowBreed] = useState(false);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // Configura o estado inicial quando o modal é aberto
  useEffect(() => {
    if (showModal) {
      let specie;

      // Verifica se o form já possui um valor para 'specie'
      const formSpecie = form.getFieldValue('specie');

      if (formSpecie) {
        specie = formSpecie;
      } else {
        // Verifica se 'document' é um array
        if (Array.isArray(document)) {
          // Busca o primeiro item no array que tenha a propriedade 'specie'
          specie = document.find(item => item.specie)?.specie;
        } else if (document && typeof document === 'object') {
          // Se for um objeto, acessa diretamente a propriedade 'specie'
          specie = document.specie;
        }
      }

      // Se encontramos um valor para 'specie', chamamos 'handlePetsChange'
      if (specie) {
        handlePetsChange(specie);
      }
    }
  }, [showModal, document, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  const handlePetsChange = value => {
    form.resetFields(['breed', 'size']);

    if (value === 'puppy') {
      setBreedOptions(puppyOptions);
      setSizeBrands(sizeBrandsOptions);
      setShowBreed(true);
      setShowSize(true);
    } else if (value === 'cat') {
      setBreedOptions(catOptions);
      setShowBreed(true);
      setShowSize(false);
    } else if (value === 'bird') {
      setBreedOptions(buildBirdOptions);
      setShowBreed(true);
      setShowSize(false);
    } else {
      setShowBreed(false);
      setShowSize(false);
    }
  };

  const disabledDate = current => {
    // Pode desabilitar datas do dia atual para frente
    return current && current > dayjs().endOf('day');
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'pet_registration'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyInput name="name" />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                name="specie"
                onChange={handlePetsChange}
                options={specieOptions}
              />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                label="predominant_color"
                name="color"
                options={petsColorOptions}
                showSearch
                allowClear
              />
            </Col>
          </Row>
          {showBreed && (
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={showSize ? 12 : 24} lg={showSize ? 12 : 24}>
                <JarvislySelect
                  name="breed"
                  options={breedOptions}
                  showSearch
                  allowClear
                />
              </Col>
              {showSize && (
                <Col xs={24} sm={12} lg={12}>
                  <JarvislySelect
                    name="size"
                    options={sizeBrands}
                    showSearch
                    allowClear
                  />
                </Col>
              )}
            </Row>
          )}
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={12} lg={12}>
              <JarvislyDatePicker
                name="birthdate"
                disabledDate={disabledDate}
              />
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <JarvislyDatePicker
                label="last_vaccination"
                name="vaccinatedAt"
                disabledDate={disabledDate}
              />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={24}>
              <JarvislyInput name="observation" />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyPetsModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { isWorking, refreshDocument, document } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'pets' tab
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const petsTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------
  const onConfirm = async ({ body }) => {
    try {
      const isEditing = selectedRecord?._id; // Verifica se há um pet selecionado para edição
      const axiosPetsOptions = {
        url: '/v1/pets',
        _id: isEditing ? selectedRecord._id : undefined, // Usa o ID se estiver editando
        data: body,
      };

      // Realiza a operação de upsert para pets
      const [response] = await axiosService.upsert(axiosPetsOptions);
      if (response) {
        // Se estamos adicionando um novo pet, atualiza os IDs dos pets na unidade
        if (!isEditing) {
          const existingPetsIds = document?.petsIds || [];
          const newPetId = response._id;
          const updatedPetsIds = [...existingPetsIds, newPetId];

          const axiosUnitsOptions = {
            url: '/v1/units',
            _id: document?._id,
            data: { petsIds: updatedPetsIds },
          };

          await axiosService.upsert(axiosUnitsOptions);
        }
      }

      refreshDocument();
      setShowModal(false);

      // setShowPetsModal(false);
      setSelectedRecord(null); // Limpa a seleção após a operação

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-pet"
      document={selectedRecord}
      requiredFields={[
        'name',
        'specie',
        'color',
        'breed',
        'size',
        'birthdate',
        'vaccinatedAt',
      ]}
      showSubmitButton={false}
      onConfirm={onConfirm}
      // autoFocus="nameResponsable"
    >
      <ModalComponent
        {...petsTabProps}
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default JarvislyPetsModal;
