import { Card, Row, Col, Form, Badge, InputNumber } from 'antd';
import {
  JarvislyNumber,
  JarvislySelect,
  JarvislyTextArea
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appveerticalService from 'services/appveerticalService';
import { translateX } from 'utils/helpers';
import { useSelector } from 'react-redux';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const InstallationTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { isDisabled } = props;

  // local variables I ---------------------------------------------------------

  // appveerticalService.buildDevicesCategoriesOptions(parameters);
  const devicesFormatOptions = appveerticalService.buildDevicesFormatOptions();
  const devicesMaterialOptions =
    appveerticalService.buildDevicesMaterialOptions();
  const devicesInstallationLocationOptions =
    appveerticalService.buildDevicesInstallationLocationOptions();

  const { parameters } = useSelector(s => s.moduleSlice);
  const locationsOptions = buildLocationsOptions();
  const sectorsOptions = buildSectorsOptions();

  // component states ----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('tank_dimensions')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="location"
              name="locationId"
              options={locationsOptions}
              required
            />
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="sector"
              name="sectorId"
              options={sectorsOptions}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              label="installation_location"
              name={['water_level_sensor', 'tankInstallation']}
              options={devicesInstallationLocationOptions}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={12}>
            <JarvislySelect
              label="format"
              name={['water_level_sensor', 'tankShape']}
              options={devicesFormatOptions}
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={12}>
            <JarvislySelect
              label="material"
              name={['water_level_sensor', 'tankMaterial']}
              options={devicesMaterialOptions}
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            {/* <JarvislyNumber
              label="depth"
              name={['water_level_sensor', 'depth']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            /> */}
            <Form.Item
              label={translateX('depth')}
              name={['water_level_sensor', 'depth']}
              tooltip={translateX('base_sensor_distance')}
            >
              <InputNumber
                min={1}
                max={62000}
                addonBefore={null}
                addonAfter={'cm'}
                decimalSeparator=","
                suffixStyle={{ width: 60 }}
                required
                disabled={isDisabled}
                style={{
                  width: '100%',
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={8} lg={8}>
            <JarvislyNumber
              label="width"
              name={['water_level_sensor', 'width']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyNumber
              label="length"
              name={['water_level_sensor', 'length']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} lg={24}>
            <JarvislyTextArea name="note" />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildSectorsOptions() {
    return (
      parameters?.sectors?.map(sector => {
        return {
          value: sector._id,
          text: sector.label,
          label: (
            <>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: sector?.foreColor,
                  backgroundColor: sector?.backColor,
                  marginTop: 4,
                }}
              />
              {sector.label}
            </>
          ),
          disabled: false,
        };
      }) || []
    );
  }

  function buildLocationsOptions() {
    return (
      parameters?.locations?.map(location => {
        return {
          value: location._id,
          text: `${location.label}`,
          label: (
            <>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: location?.foreColor,
                  backgroundColor: location?.backColor,
                  marginTop: 4,
                }}
              />
              {location.label}
            </>
          ),
          disabled: false,
        };
      }) || []
    );
  }
};

export default InstallationTab;
