import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const EllipsisDropdown = props => {
  // const menu = {
  //   key: '1',
  //   label: (
  //     <a
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       href="https://www.antgroup.com"
  //     >
  //       1st menu item
  //     </a>
  //   ),
  // };

  return (
    <Dropdown
      menu={{ items: props.menu || <Menu /> }}
      // placement={props.placement}
      trigger={['click']}
    >
      <div className="ellipsis-dropdown">
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );
};

EllipsisDropdown.propTypes = {
  trigger: PropTypes.string,
  placement: PropTypes.string,
};

export default EllipsisDropdown;
