import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {
  JarvislySwitch,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import appService from 'services/appService';
import appsindicoService from 'services/appsindicoService';
import axiosService from 'services/axiosService';
import Utils from 'utils';
import { translateX } from 'utils/helpers';

const AgreementTab = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, refreshDocument } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const translationsOptions =
    appsindicoService.buildTranslationsOptions(parameters);
  const t = translationsOptions;
  const md = Utils.getBreakPoint(useBreakpoint()).includes('md');

  // component states ----------------------------------------------------------
  const [fireBrigade, setFireBrigade] = useState({
    specialAttention:
      document?.__agreement?.fireBrigade?.specialAttention || false,
    doorBreakIn: document?.__agreement?.fireBrigade?.doorBreakIn || false,
  });

  const [agreement, setAgreement] = useState({
    declaration: document?.__agreement?.declaration || false,
    commitment: document?.__agreement?.commitment || false,
    aware: document?.__agreement?.aware || false,
  });

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onSaveTab = async body => {
    // const agreement = body?.__agreement || {};
    if(agreement ===true) {
      try {
        const agreementOptions = {
          url: '/v1/agreement',
          _id: document?.agreementId,
          data: {
            declaration: agreement?.declaration,
            commitment: agreement?.commitment,
            aware: agreement?.aware,
            fireBrigade: {
              doorBreakIn: fireBrigade.doorBreakIn,
              specialAttention: fireBrigade.specialAttention,
            },
          },
        };
        const [agreementResponse] = await axiosService.upsert(agreementOptions);
        if (agreementResponse && document?._id) {
          const unitsOptions = {
            url: '/v1/units',
            _id: document?._id,
            data: { agreementId: agreementResponse._id },
          };
          await axiosService.upsert(unitsOptions);
        }
        refreshDocument();
      } catch (error) {
        console.error(error);
      }
    } else if (agreement === false) {
      appService.message('w', 'required_fields', 'onSave');
      console.log('agreement false -->', agreement)
      return false;
    }
  };

  useImperativeHandle(ref, () => ({ onSaveTab }));

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <JarvislyTitle title={'fire_brigade_info'} className="mb-0" />
        {/* FIRE BRIGADE */}
        <Row
          style={{
            paddingBottom: 24,
            paddingTop: 24,
            borderBottomColor: '#e6ebf1',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
          }}
        >
          <Col xs={24} md={22} className="pr-4">
            <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
              {translateX('special_attention')}
            </h4>

            <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
              {translateX('special_attention_description')}
            </div>
          </Col>

          <Col
            xs={24}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <JarvislySwitch
              name={['__agreement', 'fireBrigade', 'specialAttention']}
              x-no-label
              className="mt-3"
              checkedChildren={t.yes}
              unCheckedChildren={t.no}
              checked={fireBrigade.specialAttention}
              onChange={checked =>
                setFireBrigade({ ...fireBrigade, specialAttention: checked })
              }
            />
          </Col>
        </Row>

        <Row
          style={{
            paddingBottom: 24,
            paddingTop: 24,
            borderBottomColor: '#e6ebf1',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
          }}
        >
          <Col xs={24} md={22} className="pr-4">
            <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
              {translateX('emergency_case')}
            </h4>

            <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
              {translateX('emergency_case_description')}
            </div>
          </Col>

          <Col
            xs={24}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <JarvislySwitch
              name={['__agreement', 'fireBrigade', 'doorBreakIn']}
              x-no-label
              className="mt-3"
              checkedChildren={t.yes}
              unCheckedChildren={t.no}
              checked={fireBrigade.doorBreakIn}
              onChange={checked =>
                setFireBrigade({ ...fireBrigade, doorBreakIn: checked })
              }
            />
          </Col>
        </Row>

        {/* FIRE BRIGADE */}

        <JarvislyTitle title={'required_fields'} className="mb-0" />

        <Row
          style={{
            paddingBottom: 24,
            paddingTop: 24,
            borderBottomColor: '#e6ebf1',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
          }}
        >
          <Col xs={24} md={22} className=" pr-4">
            <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
              {translateX('agreement_declaration')}
            </h4>

            <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
              {translateX('agreement_declaration_description')}
            </div>
          </Col>

          <Col xs={24} md={24} order={md ? 1 : 0} className="text-center">
            <>
              <Button
                type="default"
                className="m-2 mt-3"
                // disabled={!urlConvention}
                // onClick={() => window.open(urlConvention, '_blank')}
              >
                {translateX('convention')}
              </Button>
              <Button
                type="default"
                className="m-2 mt-3"
                // disabled={!urlRegulation}
                // onClick={() => window.open(urlRegulation, '_blank')}
              >
                {translateX('regulation')}
              </Button>
            </>
          </Col>

          <Col
            xs={24}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <JarvislySwitch
              name={['__agreement', 'declaration']}
              x-no-label
              className="mt-3"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={agreement.declaration}
              onChange={checked =>
                setAgreement({ ...agreement, declaration: checked })
              }
              required
            />
          </Col>
        </Row>

        {/* AGREEMENT */}

        <Row
          style={{
            paddingBottom: 24,
            paddingTop: 24,
            borderBottomColor: '#e6ebf1',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
          }}
        >
          <Col xs={24} md={22} className="pr-4">
            <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
              {translateX('agreement_commitment')}
            </h4>

            <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
              {translateX('agreement_commitment_description')}
            </div>
          </Col>

          <Col
            xs={24}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <JarvislySwitch
              name={['__agreement', 'commitment']}
              x-no-label
              className="mt-3"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={agreement.commitment}
              onChange={checked =>
                setAgreement({ ...agreement, commitment: checked })
              }
              required
            />
          </Col>
        </Row>
        <Row
          style={{
            paddingBottom: 24,
            paddingTop: 24,
            borderBottomColor: '#e6ebf1',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
          }}
        >
          <Col xs={24} md={22} className="pr-4">
            <h4 className={`mb-2 ${md ? 'text-left' : 'text-center'}`}>
              {translateX('agreement_aware')}
            </h4>

            <div className={`mb-2 ${md ? 'text-justify' : 'text-center'}`}>
              {translateX('agreement_aware_description')}
            </div>
          </Col>

          <Col
            xs={24}
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <JarvislySwitch
              name={['__agreement', 'aware']}
              x-no-label
              className="mt-3"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={agreement.aware}
              onChange={checked =>
                setAgreement({ ...agreement, aware: checked })
              }
              required
            />
          </Col>
        </Row>
        {/* AGREEMENT */}
      </Card>
    </>
  );
});

export default AgreementTab;
