import React, { useEffect, useRef, useState } from 'react';
import { Col, Input, Modal, Row, Form, DatePicker } from 'antd';
import { getDateFormatByLocale, translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import axiosService from 'services/axiosService';
import { MULTI_ORDERS_API } from '../../../orders';
import dayjs from 'dayjs';
import appService from 'services/appService';
import UploadFirebaseComponent from 'components/jarvisly-components/others/UploadFirebaseComponent';

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const AddVoucherModal = props => {
  // props deconstruction ------------------------------------------------------
  const {
    _id,
    refreshDocument,
    setSelectedPassenger,
    selectedPassenger,
    showModal,
    setShowModal,
    document,
  } = props;

  // local variables ------------------------------------------------------------
  const [form] = Form.useForm();
  const { status, travellers } = document || {};
  const elRefs = { voucherNumber: useRef(null), voucherDate: useRef(null) };

  // component states -----------------------------------------------------------
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (!showModal) return;

    setEdit(status === 'waiting_for_voucher');

    if (selectedPassenger) {
      selectedPassenger.voucherDate = dayjs(selectedPassenger?.voucherDate);

      if (document) {
        const attach =
          selectedPassenger.voucherUrl && document?.__attachments?.length > 0
            ? document?.__attachments?.filter(
                a => a.url === selectedPassenger.voucherUrl,
              )
            : [];
        setAttachments(attach);
      }
    }

    form.setFieldsValue(selectedPassenger);
  }, [selectedPassenger, document, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onUploadBefore = async () => setLoading(true);
  const onUploadAfter = async () => setLoading(false);
  const onUploadError = async () => setLoading(false);

  const onAfterclose = () => {
    form.resetFields();
    setSelectedPassenger(null);
    setAttachments([]);
    setUpdating(false);

    if (forceRefresh) {
      setForceRefresh(false);
      refreshDocument(null, true);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onFinishFailure = info => {
    const fieldName = info?.errorFields?.[0]?.name?.[0];
    if (fieldName) elRefs?.[fieldName || 0]?.current?.focus();
  };

  const onFinish = async values => {
    if (attachments?.length === 0) {
      appService.messageDestroy('attach');
      appService.message('f', 'attach_voucher_required', 'attach', 6);
      return;
    }

    setUpdating(true);
    setForceRefresh(true);

    const idx = travellers.findIndex(t => t._id === selectedPassenger._id);

    if (idx > -1) {
      travellers[idx] = {
        ...travellers[idx],
        ...values,
        selectedPassenger,
        voucherUrl: appService.getAttachmentUrlByTag('voucher', attachments),
      };
    }

    const data = {
      attachmentIds: appService.parseAttachmentsState([
        ...document?.__attachments,
        ...attachments,
      ]),
      travellers,
    };

    let axiosOptions = {
      url: `${MULTI_ORDERS_API}`,
      _id,
      data,
    };

    await axiosService.put(axiosOptions);

    onClose();
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Form
      name="add-voucher-form"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailure}
      layout="vertical"
      autoComplete="off"
    >
      <Modal
        title={translateX('add_voucher')}
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(edit ? 'save' : 'close')}
        onOk={() => (edit ? form.submit() : onClose())}
        onCancel={onClose}
        getContainer={false}
        okType={edit ? 'primary' : 'default'}
        confirmLoading={updating}
        afterClose={onAfterclose}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: loading }}
      >
        <Row gutter={ROW_GUTTER} className="mt-4">
          {/* PASSENGER */}
          <Col xs={24} sm={10}>
            <Form.Item label={translateX('passenger')}>
              <Input value={selectedPassenger?.name} disabled={true} />
            </Form.Item>
          </Col>
          {/* PASSENGER */}

          {/* VOUCHER NUMBER */}
          <Col xs={24} sm={7}>
            <Form.Item
              name="voucherNumber"
              label={translateX('voucher_number')}
              required
              rules={[
                {
                  required: true,
                  message: translateX('required'),
                },
              ]}
            >
              <Input ref={elRefs?.voucherNumber} autoFocus />
            </Form.Item>
          </Col>
          {/* VOUCHER NUMBER */}

          {/* VOUCHER DATE */}
          <Col xs={24} sm={7}>
            <Form.Item
              name="voucherDate"
              label={translateX('issue_date')}
              required
              rules={[
                {
                  required: true,
                  message: translateX('required'),
                },
              ]}
            >
              <DatePicker
                className="w-100 no-border-radius"
                name="voucherDate"
                ref={elRefs?.voucherDate}
                format={getDateFormatByLocale()}
              />
            </Form.Item>
          </Col>
          {/* VOUCHER DATE */}
        </Row>

        <Row gutter={ROW_GUTTER} className="mt-4">
          {/* VOUCHER ATTACHMENT */}
          <Col xs={24}>
            <UploadFirebaseComponent
              hideUploadButton={true}
              buttonTitle="Upload"
              name="voucher"
              tags="voucher"
              listType="picture"
              attachments={attachments}
              setAttachments={setAttachments}
              onUploadBefore={onUploadBefore}
              onUploadAfter={onUploadAfter}
              onUploadError={onUploadError}
              isCroppingEnabled={false}
              accept={'.pdf'}
              fileSize={6144} // 6mb
              isDisabled={!edit}
              fileFolder="orders/vouchers"
              maxFiles={1}
            />
          </Col>
          {/* VOUCHER ATTACHMENT */}
        </Row>
      </Modal>
    </Form>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AddVoucherModal;
