import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyNumber,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { forwardRef, useContext, useEffect } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, ColorPicker, Form, Modal, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appService from 'services/appService';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showParametersModal,
    setShowParametersModal,
    isDisabled,
    setSelectedRecord,
    selectedRecord,
  } = props;

  // local variables I ----------------------------------------------------------

  // component states -----------------------------------------------------------
  // const [tabIdx, setTabIdx] = useState();


  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showParametersModal) {
      form?.setFieldsValue(selectedRecord);
    }
  }, [selectedRecord, showParametersModal, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowParametersModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={translateX('parameters')}
        destroyOnClose={true}
        open={showParametersModal}
        // width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'responsible'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={14} lg={14}>
              <JarvislyInput name="name" label="name" />
            </Col>
            <Col xs={20} sm={6} lg={8}>
              <JarvislyInput name="abbreviation" label="abbreviation" />
            </Col>
            <Col xs={4} sm={4} lg={2}>
              {/* COLOR */}
              <Col xs={24} sm={2}>
                <Form.Item
                  name="backColor"
                  // style={{ width: '0%', marginTop: '25px' }}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '25px',
                  }}
                  required={true}
                >
                  <ColorPicker
                    disabled={isDisabled}
                    value={form?.getFieldValue('backColor')}
                    onChange={backColor => form.setFieldsValue({ backColor })}
                    defaultFormat="hex"
                    size="small"
                  />
                </Form.Item>
              </Col>
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={12} lg={12}>
              <JarvislyInput name="reference" label="reference" />
            </Col>
            <Col xs={24} sm={6} lg={6}>
              <JarvislyNumber
                name="ramal"
                label="direct_call"
                min={1}
                max={62000}
                addonBefore={null}
                addonAfter={null}
                decimalSeparator=","
                suffixStyle={{ width: 60 }}
              />
            </Col>
            <Col xs={24} sm={6} lg={6}>
              <JarvislyNumber
                label="floors"
                name="floors"
                min={1}
                max={62000}
                addonBefore={null}
                addonAfter={null}
                decimalSeparator=","
                suffixStyle={{ width: 60 }}
              />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const BlockModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { parameters, refreshParameters, selectedRecord, setShowParametersModal} = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    // color
    body._id = selectedRecord?._id;

    const axiosOptions = {
      url: `/v1/units-parameters/add-to-set/${parameters?._id}/$push/blocks`,
      data: body,
    };

    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.put(axiosOptions);

    setShowParametersModal(false);
    refreshParameters(true);
    return body;
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-blocks"
      document={selectedRecord}
      requiredFields={['name', 'abbreviation', 'floors']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      abortComponentSave={true}
      autoFocus="name"
    >
      <ModalComponent {...props} />
    </JarvislyFormProvider>
  );
});

export default BlockModal;
