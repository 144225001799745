import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { printGlobalStateToConsole } from 'configs/ConfigIndex';
import app from 'configs/ConfigIndex';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

const LoadingWrapper = styled('div')`
  //background-color: #ffffff;
  background-color: ${app.backgroundColor};
  z-index: 1000;

  ${props =>
    props.cover === 'content'
      ? `
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	`
      : ''}

  ${props =>
    props.cover === 'page'
      ? `
		position: fixed;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	`
      : ''}
`;

const Loading = props => {
  const { align = 'center', cover = 'content', forceShow = false } = props;

  const { firstLoad } = useSelector(state => state.theme);

  if (firstLoad && !forceShow) {
    return null;
  } else {
    return (
      <LoadingWrapper
        className={`${align ? `text-${align}` : ''}`}
        style={{ backgroundColor: 'white' }}
        cover={cover}
        onDoubleClick={printGlobalStateToConsole}
      >
        <Spin indicator={Icon} />
      </LoadingWrapper>
    );
  }
};
export default Loading;
