import CrmList from './List/List';
import CrmForm from './Form/Form';
import {
  CrmFormHeaderTitle,
  CrmFormHeaderSubtitle,
  CrmFormHeaderColumn1,
  CrmFormHeaderColumn3,
} from './Components';
import { LiaFunnelDollarSolid } from 'react-icons/lia';
import dayjs from 'dayjs';
import { getFromLocalStorage } from '../../../../utils/helpers';
import CrmParametersModal from './Parameters/ParametersModal';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
// const MENU_AREA = 'administrative';
// const MENU_GROUP = 'commercial';

const MENU_GROUP = 'sales';
const MODULE_TITLE = 'crm';
const MODULE_ICON = LiaFunnelDollarSolid;
export const CRM_API = '/v1/crm';
// export const CRM_ROUTE = `/app/${MENU_AREA}/${MENU_GROUP}/${MODULE_TITLE}`;
export const CRM_ROUTE = `/app/${MENU_GROUP}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const CRM_MODULE = {
  rootName: 'crm',
  name: 'crm',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  url: CRM_ROUTE,
  api: CRM_API,
  routes: [`${CRM_ROUTE}/:_id`, CRM_ROUTE],

  listState: {
    showParameters: false,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for ple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: false, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: [
      'customer',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],

    search: {},

    textSearch: {
      default: '',
    },
  },

  methods: {
    decorateDocument,
  },

  documentState: {
    showParameters: true,
    disableLockForEdit: false,
    initTab: 'customer',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_opportunity',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },

  components: {
    // module index
    dashboard: null,
    list: props => <CrmList {...props} />,
    grid: null,
    kanban: null, // props => <CrmKanban {...props} />,

    // module form
    form: (props, ref) => <CrmForm {...props} ref={ref} />,
    formHeader: null,
    formHeaderTitle: props => <CrmFormHeaderTitle {...props} />,
    formHeaderSubtitle: props => <CrmFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <CrmFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null, //props => <CrmFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <CrmFormHeaderColumn3 {...props} />,
    formHeaderTags: null, // props => <CrmFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,

    // module parameters
    parameters: props => <CrmParametersModal {...props} />,

    // data preview
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },
};
export default CRM_MODULE;
function decorateDocument(doc) {
  if (doc?.followUp && Array.isArray(doc.followUp)) {
    doc.followUp = doc.followUp.map(f => {
      f.label = (
        <>
          <div>
            Data de criação:
            {dayjs(f.createdAt).format('DD/MM/YYYY HH:mm:ss')}
          </div>
        </>
      );
      f.children = (
        <>
          <div>Comentario: {f.text}</div>
          <div>Lembrete: {dayjs(f.reminderDate).format('DD/MM/YYYY')}</div>
        </>
      );
      return f;
    });
  }
  return doc;
}
