import { GoTools } from 'react-icons/go';
import { getFromLocalStorage } from 'utils/helpers';
import IotDevicesList from './List/List';
import IotDevicesParametersModal from './Parameters/ParametersModal';
import IotDevicesForm from './Form/Form';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import {
  IotDevicesFormHeaderTags,
  IotDevicesFormHeaderTitle,
  IotDevicesHeaderCustomIcons,
} from './Components';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const MENU_AREA = 'monitoring';
// const MENU_GROUP = 'operational';
const MODULE_TITLE = 'water_level';
const MODULE_ICON = GoTools;
export const APPVEERTICAL_IOTDEVICES_API = '/v1/iot-device';
// /app/monitoring/water_level
export const APPVEERTICAL_IOTDEVICES_ROUTE = `/app/${MENU_AREA}/${MODULE_TITLE}`;
// export const APPVEERTICAL_IOTDEVICES_ROUTE = `/app/${MENU_AREA}/${MENU_GROUP}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const APPVEERTICAL_IOTDEVICES_MODULE = {
  rootName: 'iotDevices',
  name: 'iotDevices',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: APPVEERTICAL_IOTDEVICES_API,
  url: APPVEERTICAL_IOTDEVICES_ROUTE,
  routes: [
    `${APPVEERTICAL_IOTDEVICES_ROUTE}/:_id`,
    APPVEERTICAL_IOTDEVICES_ROUTE,
  ],

  listState: {
    showParameters: false,
    // viewMode: ['list', 'dashboard'], // 'list', 'kanban', 'grid' or 'dashboard'
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {
      status: [
        // 'not_updated',
        // 'pending_update',
        // 'waiting_for_update',
        // 'updated',
        'online',
        'offline',
        'calibrating',
      ],
      type: ['temperature_sensor', 'water_level_sensor'],
      category: ['school', 'company', 'hospital'],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      lastReadAt: -1,
    },

    sorterOptions: [
      'lastReadAt',
      '_metadata.counter',
      '_metadata.audit.updatedAt',
      'status',
      'title',
      'channel',
      'type',
      'location',
      'sector',
    ],

    search: {},

    textSearch: {},
  },

  methods: {
    decorateDocument,
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'device',
    fieldName: 'name',
    newDocumentTitle: 'new_device',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'calibrate',
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },

  components: {
    // module index
    dashboard: null, // props => <IotDashboardWaterLevel {...props} />,
    list: props => <IotDevicesList {...props} />,
    grid: null,
    kanban: null,

    // module form
    form: props => <IotDevicesForm {...props} />,
    formHeader: null,
    formHeaderTitle: props => <IotDevicesFormHeaderTitle {...props} />,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: props => <IotDevicesFormHeaderTags {...props} />,
    formHeaderCustomIcons: props => <IotDevicesHeaderCustomIcons {...props} />,

    // module parameters
    parameters: props => <IotDevicesParametersModal {...props} />,

    // data preview
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },
};

// EXPORT ********************************************************************
// ***************************************************************************
export default APPVEERTICAL_IOTDEVICES_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.lastReadAt) doc.lastReadAt = dayjs(doc.lastReadAt);
  if (doc?.firstReadAt) doc.firstReadAt = dayjs(doc.firstReadAt);
  return doc;
}
