import { Card, Row, Col, Descriptions } from 'antd';
import {
  JarvislyNumber
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const SettingsTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const descriptionItems = buildDescriptionItems();
  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('Parametros')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="downtime"
              name={['field1', 'downtime']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'mn'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>

          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="alarm_exceeded_min"
              name={['field1', 'alarmExceededMin']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="alarm_exceeded_max"
              name={['field1', 'alarmExceededMax']}
              min={0}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="calibration_time"
              name={['field1', 'calibrationtime']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'hr'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
        </Row>
      </Card>
      <Card title={translateX('calibration')}>
        <Descriptions column={2} bordered items={descriptionItems} />
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDescriptionItems() {
    return [
      {
        key: '1',
        label: translateX('maximal_distance'),
        children: document?.field1?.finishRangeValue || translateX('n_a'),
      },
      {
        key: '2',
        label: translateX('minimum_distance'),
        children: document?.field1?.startRangeValue || translateX('n_a'),
      },
      {
        key: '3',
        label: translateX('first_reading'),
        children: dayjs(document?.lastReadAt).fromNow() || translateX('n_a'),
      },
      {
        key: '4',
        label: translateX('last_reading'),
        children: dayjs(document?.lastReadAt).fromNow() || translateX('n_a'),
      },
    ];
  }
};

export default SettingsTab;
