import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Card, Col, Flex, Row, Table, Form, Button } from 'antd';
import MultiQuotationsOrderPassengerModal from './Modals/PassengerModal';
import {
  AlertOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  parseCpfToDisplay,
  setLocale,
  sort,
  translateWord,
  translateX,
} from 'utils/helpers';
import appService from 'services/appService';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  goFocus,
  JarvislyEditViewRemoveItem,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
} from 'components/jarvisly-components/others/FormComponents';
import axiosService from 'services/axiosService';
import { API_ENTITIES } from 'constants/ApiConstant';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import Icon from 'components/util-components/Icon';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const EmergencyForm = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { elRefs, form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({ submit: form.submit }));

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Form.List name="emergencyContacts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Row gutter={ROW_GUTTER} align="bottom" key={key}>
                  <Col xs={24} md={13}>
                    <JarvislyInput
                      {...restField}
                      name={[name, 'name']}
                      x-no-label
                      placeholder={translateX('name')}
                      required={true}
                    />
                  </Col>
                  <Col xs={24} md={9}>
                    <JarvislyPhone
                      {...restField}
                      name={[name, 'phone']}
                      x-no-label
                      placeholder={translateX('phone')}
                      required={true}
                    />
                  </Col>
                  {/*<Col xs={24} md={9}>*/}
                  {/*  <JarvislyInput*/}
                  {/*    {...restField}*/}
                  {/*    name={[name, 'relationship']}*/}
                  {/*    x-no-label*/}
                  {/*    placeholder={translateX('kinship_degree')}*/}
                  {/*    required={true}*/}
                  {/*  />*/}
                  {/*</Col>*/}
                  <Col xs={24} md={2}>
                    <Button
                      style={{ marginBottom: 24 }}
                      disabled={fields.length <= 1}
                      icon={<MinusCircleOutlined />}
                      onClick={() => remove(name)}
                    />
                  </Col>
                </Row>
              );
            })}
            <Button
              type="dashed"
              onClick={() => {
                add();
                setTimeout(
                  () => goFocus(elRefs, [fields.length, 'name'].join(',')),
                  0,
                );
              }}
              block
              icon={<PlusOutlined />}
            >
              {translateX('contact')}
            </Button>
          </>
        )}
      </Form.List>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

// COMPONENT ********************************************************************
// ******************************************************************************
const MultiQuotationsCheckoutStep1 = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    setStep,
    documentContext,
    travellers,
    setTravellers,
    setEnabledSteps,
    enabledSteps,
    buyer,
    setBuyer,
    isMobile,
    setUpdating,
    isUpdating,
    isDisabled,

    emergencyContacts,
    setEmergencyContacts,
  } = props;

  // local variables I ---------------------------------------------------------
  const { filter } = documentContext;
  const emergencyFormRef = useRef();
  const unavailable =
    isWorking ||
    !travellers ||
    travellers.length === 0 ||
    travellers.find(t => !t.cpf && !t.noCpf);
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------
  const [showPassengerModal, setShowPassengerModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const traveller = travellers.every(t => t.entityId && t.cpf);
    if (!traveller) {
      appService.notification(
        'w',
        'message_complete_passenger',
        'not_allowed',
        '',
        10,
      );
    }
  });
  // methods -------------------------------------------------------------------
  const onAddPassenger = () => {
    setSelectedRecord(null);
    if (!filter?.destiny || !filter?.departure || !filter?.arrival) {
      appService.message('e', 'incomplete_data');
      return null;
    }
    setShowPassengerModal(true);
  };

  const onEditPassenger = record => {
    if (!filter?.destiny || !filter?.departure || !filter?.arrival) {
      appService.message('e', 'incomplete_data');
      return null;
    }

    setSelectedRecord(record);
    setShowPassengerModal(true);
  };

  const onDeletePassenger = async value => {
    setTravellers([...travellers.filter(t => t?.key !== value.key)]);
  };

  const onModalCallbackFn = async data => {
    setSelectedRecord(null);
    if (!data || Object.keys(data).length === 0 || isDisabled) return;

    const found = travellers.find(t => t.key === data?.key);
    let p;
    if (!found) {
      p = [...travellers, data].sort((a, b) => sort(a, b, 'key'));
    } else {
      p = [...travellers.filter(t => t.key !== data.key), data].sort((a, b) =>
        sort(a, b, 'key'),
      );
    }

    setTravellers(p);
  };

  const onNext = async () => {
    if (isDisabled) return setStep(1);

    setUpdating(true);

    const b = buyer ? { ...buyer } : {};

    const traveller = travellers.every(t => t.entityId && t.cpf);

    if (!b?.cpf && traveller?.entityId) {
      const axiosOptions = {
        url: API_ENTITIES,
        returnAsObject: true,
        _id: traveller.entityId,
      };

      const [doc] = await axiosService.get(axiosOptions);

      setBuyer({ ...doc });
    }

    emergencyFormRef?.current?.submit();
  };

  const onConfirmEmergyForm = ({ body }) => {
    setEmergencyContacts(body?.emergencyContacts);

    setUpdating(false);
    setStep(1);
    if (enabledSteps && !enabledSteps.includes(1)) {
      setEnabledSteps([...enabledSteps, 1]);
    }
  };

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <div className="w-100">
      <MultiQuotationsOrderPassengerModal
        {...props}
        onCallbackFn={onModalCallbackFn}
        record={selectedRecord}
        showModal={showPassengerModal}
        setShowModal={setShowPassengerModal}
        isWorking={isWorking}
        travellers={travellers}
        setSelectedRecord={setSelectedRecord}
      />

      <Row gutter={ROW_GUTTER}>
        {/* PASSENGERS */}

        <Col xs={24} lg={12}>
          <Card className="w-100 strong-border cad-with-body-no-padding">
            <Table
              rowKey="key"
              className="hide-last-line-table"
              columns={tableColumns.filter(x => !x.hidden)}
              dataSource={travellers}
              pagination={false}
              loading={isWorking}
            />
            <Flex justify="center">
              <FormButton
                title="passenger"
                icon={<PlusOutlined />}
                style={{ width: 130, margin: 16 }}
                className="btn-add-passenger"
                onClick={onAddPassenger}
                disabled={isUpdating}
              />
            </Flex>
          </Card>
        </Col>
        {/* PASSENGERS */}

        {/* RESPONSIBLES */}
        <Col xs={24} lg={12}>
          <Card
            title={
              <Flex align="center" style={{ color: 'darkcyan' }}>
                <div>
                  <Icon type={AlertOutlined} />
                </div>
                <div style={{ marginLeft: 8 }}>
                  {translateX('emergency_contacts')}
                </div>
              </Flex>
            }
            className="w-100 strong-border"
          >
            <JarvislyFormProvider
              name="emergency-form"
              showSubmitButton={false}
              onConfirm={onConfirmEmergyForm}
              abortComponentSave={true}
              initialValues={{
                emergencyContacts: emergencyContacts || [
                  {
                    name: '',
                    phone: '',
                    relationship: '',
                  },
                ],
              }}
              size="large"
              disabled={isDisabled}
              document={emergencyContacts}
            >
              <EmergencyForm {...props} ref={emergencyFormRef} />
            </JarvislyFormProvider>
          </Card>
        </Col>
        {/* RESPONSIBLES */}
      </Row>

      {/*PREV/NEXT*/}
      {!isDisabled ? (
        <Flex justify={isMobile ? 'center' : 'flex-end'} align="center">
          <FormButton
            title="next"
            type={travellers?.length > 0 ? 'primary' : 'default'}
            icon={<SaveOutlined />}
            disabled={unavailable || isUpdating}
            onClick={onNext}
            style={{ width: 130 }}
          />
        </Flex>
      ) : null}
      {/* PREV/NEXT */}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: setLocale('passengers'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              <div className="d-flex" style={{ width: 200 }}>
                <ImageDescription
                  shape="circle"
                  size="small"
                  title={record?.name ? record.name : record.fullName}
                  subtitle={parseCpfToDisplay(record?.cpf)}
                  showAge={record?.birthdate}
                  referenceAge={filter?.arrival}
                  foreColor={record?.foreColor}
                  backColor={record?.backColor}
                />
              </div>
            </>
          );
        },
      },

      {
        title: translateWord('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEditPassenger}
              onDelete={onDeletePassenger}
              record={record}
              recordId={record?.key}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['passenger'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default MultiQuotationsCheckoutStep1;
