import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import { translateX } from 'utils/helpers';
import { Badge, Button, Card, Col, Row, Tooltip } from 'antd';
import {
  JarvislyFormContext,
  JarvislySelect,
  JarvislySelectAvatars,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { EditOutlined } from '@ant-design/icons';
import { CrmParametersContext } from '../../Parameters/ParametersProvider';

// COMPONENT *******************************************************************
// *****************************************************************************
const CrmCustomerTab = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);
  const {
    selectedRecord,
    setSelectedRecord,
    showOriginModal,
    setShowOriginModal,
  } = useContext(CrmParametersContext);

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, mode } = props;

  // local variables I ---------------------------------------------------------
  const { selectedCompany } = useSelector(s => s.authSlice);
  const { parameters } = useSelector(s => s.moduleSlice);
  const opportunityTypesOptions = buildOpportunityTypesOptions();
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const isDisabled = isWorking || mode === 'view';
  const team = buildTeamSelectOptions();

  // component states ----------------------------------------------------------
  const [mainValue, setMainValue] = useState(0);
  const [selectedOriginId, setSelectedOriginId] = useState(null);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    mainValue,
    setMainValue,
    selectedCompany,
    document,
    selectedRecord,
    setSelectedRecord,
    showOriginModal,
    setShowOriginModal,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onAddOrigin = () => {
    setSelectedRecord(null);
    setShowOriginModal(true);
  };
  const onEditOrigin = record => {
    setSelectedRecord(record); // Passa o registro completo
    setShowOriginModal(true);
  };

  const onOriginChange = value => {
    setSelectedOriginId(value); // Atualiza o estado quando a opção é selecionada
  };

  // local variables III --------------------------------------------------------

  const originOptions = buildOriginsOptions();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/*CUSTOMER CARD */}
      <Card title={<div className="text-muted">{translateX('customer')}</div>}>
        <JarvislyEntityForm
          {...childProps}
          entityPersonType={
            parameters?.crmParameters?.entityPersonType || 'manual'
          }
          entityPersonDefault={
            parameters?.crmParameters?.entityPersonDefault || 'company'
          }
          context="__entity"
          originRegisteredLabel="registered_associate"
          originNewLabel="new_associate"
          searchEntityLabel="search_associate"
          selectable
          showCpfCnpjPrimary={false}
        />
      </Card>
      {/*CUSTOMER CARD */}

      <Card
        title={<div className="text-muted">{translateX('opportunity')}</div>}
      >
        <Row gutter={ROW_GUTTER}>
          <Col xs={24}>
            <JarvislyTextArea
              name="opportunity"
              label="description"
              // ref={followupRef}
              rows={2}
              // label="comment"
              disabled={isDisabled}
              // value={comment}
              // onChange={({ target: { value } }) => setComment(value)}
              required
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* OPPORTUNITY TYPE */}
          <Col xs={8}>
            <JarvislySelect
              label="opportunity_type"
              name="opportunityType"
              options={opportunityTypesOptions}
              showSearch
              allowClear
              required
            />
          </Col>
          {/* OPPORTUNITY TYPE */}

          {/* ORIGIN */}
          <Col xs={8}>
            <JarvislySelect
              label="origin"
              name="originId"
              options={originOptions}
              showSearch
              allowClear
              required
              x-show-add-button={true}
              x-on-add-button-fn={onAddOrigin}
              onChange={onOriginChange} // Chamando a função ao mudar a seleção
            />
          </Col>
          {/* ORIGIN */}

          <Col xs={8}>
            <JarvislySelectAvatars
              label="first_contact"
              name="firstContactId"
              required
              members={team}
            />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildOpportunityTypesOptions() {
    return [
      {
        value: 'sale',
        text: translateX('sale'),
        label: (
          <>
            <Badge
              dot
              style={{
                marginRight: 8,
                color: 'ffff',
                backgroundColor: '#F57C00',
                marginTop: 4,
              }}
            />
            {translateX('sale')}
          </>
        ),
        disabled: false,
      },

      {
        value: 'partner',
        text: translateX('partner'),
        label: (
          <>
            <Badge
              dot
              style={{
                marginRight: 8,
                color: 'ffff',
                backgroundColor: '#00407F',
                marginTop: 4,
              }}
            />
            {translateX('partner')}
          </>
        ),
        disabled: false,
      },
    ];
  }

  function buildOriginsOptions() {
    // Verifica se existem origens disponíveis
    if (!parameters?.crmParameters?.origins?.length) {
      return [];
    }

    return parameters.crmParameters.origins.map(origin => {
      return {
        value: origin._id,
        text: origin.label,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={origin._id}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: origin?.foreColor,
                  backgroundColor: origin?.backColor,
                  marginTop: 4,
                }}
              />
              {origin.label}
            </span>
            {selectedOriginId !== origin._id &&
              mode !== 'view' && ( // Verifica se a opção está selecionada
                <Tooltip title={translateX('edit')}>
                  <Button
                    size="small"
                    className="m-2"
                    icon={<EditOutlined />}
                    onClick={() => onEditOrigin(origin)} // Passando o registro completo
                  />
                </Tooltip>
              )}
          </div>
        ),
        disabled: false, // Pode ser ajustado conforme a lógica de negócio
      };
    });
  }

  function buildTeamSelectOptions() {
    const team = selectedSubscription?.team || [];

    return team.map(t => {
      return {
        _id: t._id,
        name: t.name,
        avatarUrl: t.pictureUrl,
      };
    });
  }
};

export default CrmCustomerTab;
