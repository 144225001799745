import { useSelector } from 'react-redux';
import JarvislyEntityCardIndex from 'components/jarvisly-components/forms/JarvislyEntityCardIndex';
import { useContext, useEffect, useRef, useState } from 'react';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import ContactModal from './contact/ContactModal';
import ContactTab from './contact/ContactTab';
import { Card } from 'antd';
import { translateX } from 'utils/helpers';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const IotDevicesCustomerTab = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);
  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  const { selectedCompany } = useSelector(s => s.authSlice);


  // component states ----------------------------------------------------------
  //   const [categories, setCategories] = useState([]);
  const [mainValue, setMainValue] = useState(0);
  // const [selectedRecord, setSelectedRecord] = useState();

  // local variables II ---------------------------------------------------------
  const childProps = {
    ...props,
    mainValue,
    setMainValue,
    selectedCompany,
    // selectedRecord,
    // setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (document) {
      form.setFieldsValue({
        ...document,
      });
    }
  });
  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>


      {/*CUSTOMER CARD */}
      <JarvislyEntityCardIndex {...childProps} context="__customer" />
      {/*CUSTOMER CARD */}

      <Card title={translateX('contacts')}>
        <ContactTab {...childProps} />
      </Card>
    </>
  );
};

export default IotDevicesCustomerTab;
