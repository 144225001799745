import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import appService from 'services/appService';
import { Flex, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import { capitalize } from 'lodash';
import { useEffect } from 'react';
import entityService from 'services/entityService';
import axiosService from 'services/axiosService';
import { API_ENTITIES } from 'constants/ApiConstant';

// COMPONENT *******************************************************************
// *****************************************************************************
const ContactTab = props => {
  // props deconstruction ------------------------------------------------------
  const {
    _id,
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowContactsModal,
    setSelectedRecord,
    selectedRecord,
    refreshDocument,
  } = props;

  // local variables -----------------------------------------------------------
  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowContactsModal(true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowContactsModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    if (record?.entityId) {
      if (
        record.dataProfile.length === 1 &&
        record.dataProfile.includes('contact')
      ) {
        await axiosService
          .delete({
            url: '/v1/entity',
            _id: record.entityId,
            headers: { 'x-forcepermanentlydeletation': true },
          })
          .then();
      }

      const axiosOptions = {
        url: `/v1/entity/add-to-set/${document?.__customer._id}/$pull/moreContacts.entityId`,
        data: { _id: record?.entityId },
      };
      await axiosService.put(axiosOptions);
    }

    refreshDocument();
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="entityId"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={document?.__customer?.moreContacts || []}
        pagination={false}
        loading={!document || isWorking}
      />

      <Flex justify='center'>
        <FormButton
          title="add"
          className="m-3"
          icon={<PlusOutlined />}
          disabled={isDisabled}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="circle"
                size="small"
                title={record?.name}
                subtitle={record?.note}
                showAge={record?.birthdate}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateX('contact'),
        dataIndex: 'mobile',
        render: (_, record) => {
          return (
            <>
              <div>{parsePhoneToDisplay(record?.mobile)}</div>
              <div>{record?.email}</div>
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default ContactTab;
