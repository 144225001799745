import { Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import VehiclesRecordTab from './records/VehiclesRecordTab';

const VehiclesForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, _id, setModuleFocus } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab];

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    if (body.color?.toHexString) body.color = body.color?.toHexString();
    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        name="form-vehicles"
        document={document}
        initialValues={{ service: 'sindico' }}
        autoFocus="name"
        requiredFields={['name']}
        // showSubmitButton={tab !== 'record'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <VehiclesRecordTab {...props} activeTab={tab}/>
          </JarvislyTabProvider>
        ),
      },
    ];
  }
};
export default VehiclesForm;
