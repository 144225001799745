import { createContext, useState } from 'react';

export const CrmParametersContext = createContext({});

const CrmParametersProvider = ({ children }) => {
  // component states ----------------------------------------------------------
  const [selectedRecord, setSelectedRecord] = useState();
  const [showBusinessAreaModal, setShowBusinessAreaModal] = useState();
  const [showOriginModal, setShowOriginModal] = useState(false);

  const exposedValues = {
    selectedRecord,
    setSelectedRecord,
    showBusinessAreaModal,
    setShowBusinessAreaModal,
    showOriginModal,
    setShowOriginModal,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <CrmParametersContext.Provider value={exposedValues}>
      {children}
    </CrmParametersContext.Provider>
  );
};

export default CrmParametersProvider;
