import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Divider, Flex } from 'antd';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { GoogleSVG, FacebookSVG } from 'assets/svg/icon';
import CustomIcon from 'components/util-components/CustomIcon';
import {
  signIn,
  showLoading,
  hideAuthMessage,
  signInWithGoogle,
  signInWithFacebook,
  signInWithEmail,
} from 'store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { isValidCpf, returnOnlyNumbers, translateX } from 'utils/helpers';
import {
  FormButton,
  FormCpf,
  FormEmail,
  FormPassword,
  FormWrapper,
} from 'components/jarvisly-components/others/FormComponents';
import { notification } from 'antd';
import authService from 'services/authService';
import app from 'configs/ConfigIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const loginIdentification = app?.LOGIN_IDENTIFICATION || 'email';
const loginTheme = app?.APPLICATION_LAYER || 'adm';

// COMPONENT *******************************************************************
// *****************************************************************************

export const LoginForm = props => {
  // props deconstruction ------------------------------------------------------
  const {
    otherSignIn = true,
    showForgetPassword = true,
    onForgetPasswordClick,
    extra,
    allowRedirect = true,
  } = props;

  // local variables -----------------------------------------------------------
  const { loading, showMessage, token, redirect } = useSelector(
    s => s.authSlice,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const elRefs = {};

  // component states ----------------------------------------------------------
  const [isLoginInformed, setIsLoginInformed] = useState(false);
  const [isWorking, setIsWorking] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (token !== null && allowRedirect) {
      navigate(redirect);
    }
    if (showMessage) {
      const timer = setTimeout(() => dispatch(hideAuthMessage()), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onLogin = async values => {
    dispatch(showLoading());

    if (loginIdentification === 'email') {
      // setTimeout(()=>{
      await dispatch(signInWithEmail(values));

      // navigate('/')
      // },1000)
    } else {
      const data = {
        loginKey: loginIdentification,
        loginValue: returnOnlyNumbers(values[loginIdentification]),
        ...values,
      };

      await dispatch(signIn(data));
    }

    authService.refreshAccount();

    // const t = localStorage.getItem(AUTH_TOKEN) || null
    // dispatch(rdxSetUser(t))
  };

  const onGoogleLogin = () => {
    dispatch(showLoading());
    dispatch(signInWithGoogle());
  };

  const onFacebookLogin = () => {
    dispatch(showLoading());
    dispatch(signInWithFacebook());
  };

  const onCpfChange = async cpf => {
    const isValid = isValidCpf(cpf);
    if (!isValid) return;

    if (isLoginInformed || isWorking) return;

    try {
      const result = await authService.validateLoginByCpf(cpf, setIsWorking);

      if (result) {
        setIsLoginInformed(isValid);

        setTimeout(() => elRefs?.password?.current?.focus({ cursor: 'all' }));

        return {
          status: 'success',
          message: '',
        };
      }
    } catch (e) {
      setTimeout(() => elRefs?.cpf?.current?.focus({ cursor: 'all' }));

      notification.error({
        message: translateX('cpf_not_found'),
        description: translateX('call_to_administrator'),
        duration: 10,
      });

      return {
        status: 'error',
        message: translateX('register_not_found_for_this_cpf'),
      };
    }
  };

  const onCancel = () => {
    setIsLoginInformed(false);
    form.resetFields();
    elRefs?.cpf?.current?.focus();
  };

  // UI COMPONENTS -------------------------------------------------------------

  const renderOtherSignIn = (
    <div>
      <Divider>
        <span className="text-muted font-size-base font-weight-normal">
          {translateX('or_connect_with')}
        </span>
      </Divider>

      <div className="d-flex justify-content-center">
        {app.GOOGLE_LOGIN && (
          <Button
            onClick={() => onGoogleLogin()}
            className="mr-2"
            disabled={loading}
            icon={<CustomIcon svg={GoogleSVG} />}
          >
            Google
          </Button>
        )}

        {app.FACEBOOK_LOGIN && (
          <Button
            onClick={() => onFacebookLogin()}
            icon={<CustomIcon svg={FacebookSVG} />}
            disabled={loading}
          >
            Facebook
          </Button>
        )}
      </div>
    </div>
  );

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <FormWrapper form={form} elRefs={elRefs} onConfirm={onLogin}>
        {loginIdentification === 'cpf' && (
          <FormCpf
            autoFocus
            label="inform_your_cpf"
            elRefs={elRefs}
            prefix={<UserOutlined className="text-primary" />}
            form={form}
            disabled={isLoginInformed || isWorking}
            onChange={onCpfChange}
            a={() => setIsLoginInformed(false)}
            required
          />
        )}

        {loginIdentification === 'email' && (
          <FormEmail
            autoFocus
            elRefs={elRefs}
            prefix={<MailOutlined className="text-primary" />}
            form={form}
            required
          />
        )}

        <FormPassword
          style={{
            display: loginTheme === 'user' && !isLoginInformed ? 'none' : '',
          }}
          form={form}
          prefix={<LockOutlined className="text-primary" />}
          elRefs={elRefs}
          label={
            <div
              className={`${
                showForgetPassword
                  ? 'd-flex justify-content-between w-100 align-items-center'
                  : ''
              }`}
            >
              <span>
                {translateX(
                  loginTheme === 'user' ? 'inform_your_password' : 'password',
                )}
              </span>
              {showForgetPassword && (
                <span
                  tabIndex="-1"
                  onClick={() => onForgetPasswordClick}
                  className="cursor-pointer font-size-sm font-weight-normal text-muted"
                >
                  {translateX('forget_password')}
                </span>
              )}
            </div>
          }
          required
        />

        <Flex justify="center" align="center">
          {((loginTheme === 'user' && isLoginInformed) ||
            loginTheme === 'adm') && (
            <>
              <FormButton
                style={{ width: '100%' }}
                className="mt-3"
                title="sign_in"
                disabled={loading || isWorking}
                loading={loading}
                titleLoading="entering"
              />

              {loginTheme === 'user' && (
                <FormButton
                  style={{ width: '100%' }}
                  className="mt-3"
                  type="default"
                  disabled={loading || isWorking}
                  loading={loading}
                  onClick={onCancel}
                  title="cancel"
                />
              )}
            </>
          )}
        </Flex>

        {(app.GOOGLE_LOGIN || app.FACEBOOK_LOGIN) && otherSignIn
          ? renderOtherSignIn
          : null}
        {extra}
      </FormWrapper>

      {/*<WebSocketComponent {...props} />*/}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// PROTOTYPES ================================================================
// ===========================================================================

LoginForm.propTypes = {
  otherSignIn: PropTypes.bool,
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

// EXPORT ====================================================================
// ===========================================================================

export default LoginForm;
