import { Flex, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import appveerticalService from 'services/appveerticalService';
import { translateX } from 'utils/helpers';
import { Icon } from 'components/util-components/Icon';
import { PiPlugsConnected } from 'react-icons/pi';
import {
  TbAccessPoint,
  TbPhotoSensor2,
  TbSubmarine,
  TbPlugConnectedX,
  TbPlugConnected,
} from 'react-icons/tb';
import { PiThermometerColdLight } from 'react-icons/pi';

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesConnectedIcons = props => {
  const { document, isEmpty, pl, pr } = props;

  if (!document || isEmpty) return;

  const realStatusMinutes = 5;
  // const emulatedStatusMinutes = 30;

  const alreadyConnected = document?.firstReadAt;
  // const online =
  //   document?.lastReadAt &&
  //   (new Date() - new Date(document.lastReadAt)) / (1000 * 60) > 5;

  const onlineSeconds =
    document?.lastReadAt && dayjs().diff(document?.lastReadAt, 'seconds');

  const realStatusOnline = onlineSeconds
    ? onlineSeconds < 60 * realStatusMinutes
    : false;
  // const emulatedStatusOnline = onlineSeconds
  //   ? onlineSeconds < 60 * emulatedStatusMinutes
  //   : false;

  const lastConnectionTranslated = alreadyConnected
    ? realStatusOnline
      ? translateX('device_connected')
      : translateX(
          'device_last_connect',
          ['%LAST_CONNECTION_IN%'],
          [dayjs(document.lastReadAt).format('L LT')],
        )
    : translateX('device_never_conected');

  const colorIcon = alreadyConnected
    ? realStatusOnline
      ? 'green'
      : 'red'
    : 'gray';

  const icon = alreadyConnected
    ? realStatusOnline
      ? PiPlugsConnected
      : TbPlugConnected
    : TbPlugConnectedX;

  return (
    <>
      <Tooltip title={translateX(lastConnectionTranslated)}>
        <Flex
          align="center"
          className={`${pl ? 'pl-2' : ''} ${pr ? 'pr-2' : ''}`}
          style={{ opacity: alreadyConnected ? 1 : 0.3, height: 17 }}
        >
          <Icon
            type={icon}
            // type={connected ? ApiTwoTone : ApiOutlined}
            className={`${colorIcon} font-size-md`}
            style={{ flexShrink: 0 }}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesInfoIcons = props => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IotDevicesModelIcons {...props} pr />
      <IotDevicesConnectedIcons {...props} />
    </div>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesModelIcons = props => {
  const { document, isEmpty, pl, pr } = props;

  if (!document || isEmpty) return;

  let icon, tooltip;

  switch (document?.model) {
    case 'ultrasonic':
      icon = TbAccessPoint;
      tooltip = 'ultrassonic_sensor_model';
      break;

    case 'infra_red':
      icon = TbPhotoSensor2;
      tooltip = 'infrared_sensor_model';
      break;

    case 'probe06':
      icon = TbSubmarine;
      tooltip = 'probe_sensor_model_06';
      break;

    case 'probe20':
      icon = TbSubmarine;
      tooltip = 'probe_sensor_model_20';
      break;

    default:
      icon = PiThermometerColdLight;
      tooltip = 'temperature_sensor';
  }

  return (
    <>
      <Tooltip title={translateX(tooltip)}>
        <Flex
          align="center"
          className={`${pl ? 'pl-2' : ''} ${pr ? 'pr-2' : ''}`}
        >
          <Icon
            type={icon}
            className={`font-size-md`}
            style={{ flexShrink: 0 }}
          />
        </Flex>
      </Tooltip>
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesHeaderCustomIcons = props => {
  return <IotDevicesInfoIcons {...props} pr />;
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const DeviceTag = props => {
  const { record, uppercase } = props;
  const options = appveerticalService.buildDevicesOptions();
  const selected = record?.type
    ? options.find(d => d.value === record.type) || options[0]
    : options[0];

  return (
    <Tag
      className={uppercase ? 'text-uppercase' : 'text-capitalize'}
      style={{ margin: 4 }}
      color={selected.color}
    >
      <span style={{ color: selected?.textColor || 'white' }}>
        {selected.text}
      </span>
    </Tag>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderTitle = props => {
  const { document } = props;

  return (
    <Tooltip title={translateX('device')}>
      <span>{document?.title}</span>
    </Tooltip>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesFormHeaderTags = props => {
  return <IotDevicesStatusTag {...props} />;
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const IotDevicesStatusTag = props => {
  const record = props?.record || props?.document;
  const dse = props?.status || record?.status; // document status in english
  const documentStatus = dse && translateX(dse);

  const overdue =
    !props?.status &&
    record?._metadata?.audit?.createdAt &&
    dayjs(record._metadata.audit.createdAt).diff(dayjs(), 'days') > 1;

  return (
    <>
      {documentStatus && (
        <Tag
          style={{ margin: 4 }}
          color={getIotDevicesStatusColor(overdue ? 'overdue' : dse)}
        >
          {documentStatus}
        </Tag>
      )}
    </>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const OnOffStatusTag = props => {
  const { record } = props;

  const selected = buildOnOffStatusFilter(record?.status || 'offline');

  return (
    <Tag
      className={`text-capitalize ${props.className}`}
      style={{ margin: 4 }}
      color={selected.color}
    >
      <div style={{ marginTop: 2 }}>{selected.value}</div>
    </Tag>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
export const getIotDevicesStatusColor = status => {
  switch (status) {
    case 'calibrating':
      return 'orange';

    case 'offline':
      return 'gray';

    case 'online':
      return 'green';

    default:
      return 'gray';
  }
};

export const buildOnOffStatusFilter = status => {
  switch (status) {
    case 'online':
      return { value: translateX('online'), color: 'green' };
    case 'removed':
      return { value: translateX('removed'), color: 'red' };
    case 'calibrating':
      return { value: translateX('calibrating'), color: 'orange' };
    case 'offline':
      return { value: translateX('offline'), color: 'gray' };
    default:
      return { value: 'offline', color: 'gray' };
  }
};
