import { Table } from 'antd';
import {
  parseCpfToDisplay, translateWord,
  translateX
} from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import JarvislyEntityProfileTag from 'components/jarvisly-components/forms/Items/JarvislyEntityProfileTag';
import CUSTOMERS_MODULE from '../customers';

const CustomersList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,

    numberLineColumn,
    documentCodeColumn,
    lastUpdatedColumn,
    editViewColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const dataProfileFilter = buildDocumentStatusFilter();
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options =
      CUSTOMERS_MODULE?.initialRequest?.filterOptions?.dataProfile || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      numberLineColumn,
      documentCodeColumn,

      {
        title: translateWord('name'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                title={record?.name}
                subtitle={parseCpfToDisplay(record?.cpf)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateWord('address'),
        dataIndex: '__address',
        render: (_, record) => (
          <>
            <div>{record?.__address}</div>
            <div>{record?.__city}</div>
          </>
        ),
      },

      {
        title: translateWord('profile'),
        dataIndex: 'dataProfile',
        ...columnFilters('dataProfile', dataProfileFilter),
        // ...columnSorter('dataProfile'),
        width: 100,
        className: 'text-center',
        render: (_, record) => (
          <JarvislyEntityProfileTag
            record={record}
            space="left"
            className="mb-1"
          />
        ),
      },

      lastUpdatedColumn,
      editViewColumn,
    ].filter(x => !x.hidden);
  }
};

export default CustomersList;
