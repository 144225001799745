import { Icon } from 'components/util-components/Icon';
import {
  ApiOutlined,
  ApiTwoTone,
  BorderlessTableOutlined,
  EditOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Card, Col, Row, Grid, Tooltip, Skeleton } from 'antd';
import utils from 'utils';
import { translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentHeader from '../../jarvisly-components/others/DocumentHeader';
import Flex from '../../shared-components/Flex';
import DocumentStatusTag from '../../jarvisly-components/others-old/DocumentStatusTag';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const { useBreakpoint } = Grid;

const FormHeader = props => {
  // props deconstruction ------------------------------------------------------
  const { document, isWorking, documentHeaderRef, mode } = props;

  // local variables -----------------------------------------------------------
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('sm');

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Card
        className="card-with-picture"
        // style={{ minHeight: hasId ? 248 : 'unset' }}
      >
        <DocumentHeader
          {...props}
          ref={documentHeaderRef}
          isMobile={isMobile}
          isWorking={isWorking}
          document={document}
          mode={mode}
        />
      </Card>
    </>
  );
};

// EXPORT **********************************************************************
// *****************************************************************************

export default FormHeader;

// COMPONENT *******************************************************************
// *****************************************************************************
export const DefaultFormHeaderColumn1 = props => {
  const { document, isWorking } = props;

  return (
    <Col xs={24} sm={8}>
      {/* DOCUMENT CODE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.__documentCode ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.__documentCode && (
                <Tooltip placement="right" title={translateX('document_code')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={BorderlessTableOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold text-uppercase">
                      {document?._metadata?.__documentCode}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* DOCUMENT CODE */}

      {/* CREATED AT */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.audit?.createdAt ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.audit?.createdAt && (
                <Tooltip placement="right" title={translateX('creation_date')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={StarOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?._metadata?.audit?.createdAt &&
                        dayjs(document?._metadata?.audit?.createdAt).format(
                          'L LT',
                        )}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* CREATED AT */}

      {/* LAST UPDATE */}
      <Skeleton
        active
        loading={!document?._id && isWorking}
        paragraph={{
          rows: 0,
          width: 200,
        }}
      >
        <Row
          style={{
            display: document?._metadata?.audit?.updatedAt ? 'initial' : 'none',
          }}
        >
          <Col>
            <Row className="p-1" style={{ minHeight: 29 }}>
              {document?._metadata?.audit?.updatedAt && (
                <Tooltip placement="right" title={translateX('last_update')}>
                  <Flex
                    flexDirection="row"
                    justifyContent="start"
                    alignItems="center"
                    className="text-truncate"
                  >
                    <Icon
                      type={EditOutlined}
                      className="text-primary font-size-md"
                      style={{ flexShrink: 0 }}
                    />
                    <span className="ml-2 font-weight-semibold">
                      {document?._metadata?.audit?.updatedAt &&
                        dayjs(document?._metadata?.audit?.updatedAt).format(
                          'L LT',
                        )}
                    </span>
                  </Flex>
                </Tooltip>
              )}
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* LAST UPDATE */}
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const DefaultFormHeaderCustomIcons = props => {
  const { document, isEmpty, showStatusIcon, showApiIcon } = props;

  const hasApi = document?.apiIntegrated || document?.parameters?.integrated;

  if (!document || isEmpty) return;

  return (
    <>
      {showStatusIcon ? <DocumentStatusTag record={document} /> : null}

      {showApiIcon ? (
        <Tooltip
          title={translateX(hasApi ? 'api_integrated' : 'no_api_integration')}
        >
          <div
            className="pl-2"
            style={{ opacity: hasApi ? 1 : 0.3, height: 17 }}
          >
            <Icon
              type={hasApi ? ApiTwoTone : ApiOutlined}
              className="text-primary font-size-md"
              style={{ flexShrink: 0 }}
            />
          </div>
        </Tooltip>
      ) : null}
    </>
  );
};
