import React, { useContext, useEffect } from 'react';
import { Card } from 'antd';
import JarvislyPersonIndex from 'components/jarvisly-components/forms/JarvislyPersonIndex';
import { JarvislyFormContext } from 'components/jarvisly-components/others/FormComponents';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import JarvislyEntityForm from '../../../../../../components/jarvisly-components/forms/Items/JarvislyEntityForm';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiCustomersRecordIndex = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);
  // props deconstruction ------------------------------------------------------
  // const valueXpto = props.document?.lkq_emergency.emergencyContacts[0].name;

  props?.document?.lkq_emergency?.sort(
    (a, b) => new Date(b.departure) - new Date(a.departure),
  );

  // Agora, `sortedArray` tem o objeto com a data de departure mais recente na primeira posição.
  // props.document?.lkq_emergency = sortedArray;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <JarvislyEntityForm
          {...props}
          entityPersonType="person"
          cpfCnpjFirst
          additionalFieldsFixed
        />
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default MultiCustomersRecordIndex;
